@import '../../../../../../styles/_variables.scss';

.section-cover-page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .info-container {
    width: 50%;
    margin-left: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 20px;

    .title {
      font-size: 4rem;
      line-height: 4rem;
    }
  }

  .cover-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
