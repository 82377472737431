@import '../../../../../../styles/_variables.scss';

.dual-reporting-current-footprint-page {
  padding: calc(30px + 1.25rem) calc(70px + 1.25rem);
  position: relative;

  .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    overflow: visible;
    gap: 2rem;

    .chart-side {
      flex: 2;
      height: 100%;
    }

    .items-side {
      flex: 3;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2rem;
    }
  }
}
