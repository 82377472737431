@import '../../../styles/_variables.scss';

.flotilla-modal-overlay {
  z-index: 1;
}

#add-multi-action-modal {
  width: 50vw;
  max-width: 700px;
  min-width: 300px;
  max-height: 70vh;
  overflow: auto;
  background-color: $brand-light;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  h2 {
    margin: 0;
  }

  main {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: min-content;
    margin: 0;
    padding-bottom: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
  
    &::-webkit-scrollbar {
      display: none;
    }

    .action {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: stretch;
      gap: 1rem;
      padding: 0.5rem;
      padding-left: 1rem;
      border: 1px solid transparent;
      border-radius: 0.25rem;
      background-color: $color-grey-40;

      &:hover {
        border-color: $color-grey-700;
      }
  
      &.action--selected {
        border-color: $brand-primary;
        background-color: $color-grey-20;
      }

      #list-item {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        cursor: pointer;

        .header {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
          width: 100%;
          min-width: 0px;

          p {
            margin: unset;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          #department {
            color: #818181;
          }
        }

        #info-icons {
          display: flex;
          flex-direction: row;
          gap: 2rem;
          align-items: center;
          justify-content: center;
        }
      }

      #selected-inputs {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 2rem;
      }
    }

    #reductions {
      h4 {
        margin-bottom: 1rem;
      }
    
      section {
        display: flex;
        flex-direction: row;
        gap: 2rem;
      }
    }
  }

  footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      width: min-content;
      padding: 0 2rem;
    }
  }

  #close-button {
    z-index: 9;
  }
}