@import '../../../../../../styles/_variables.scss';

.offset-package {
  padding: calc(30px + 1.25rem) calc(70px + 1.25rem);
  position: relative;

  .offset-package-header {
    padding-right: 7rem;
  }

  .content {
    width: 100%;
    flex: 1;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;

    main {
      all: unset;
      height: fit-content;
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    .top-text {
      padding-right: 7rem;
    }

    .project-section {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 2rem;

      .project {
        flex: 1;
        border-left: 0.5rem solid $brand-dark;
        padding: 0.5rem 1rem;
        border-radius: 0.25rem;
        background-color: transparentize($brand-dark, 0.95);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;

        .header {
          display: flex;
          flex-direction: row;
          gap: 1.5rem;

          .title {
            display: flex;
            flex-direction: column;
          }

          .percentage-bubble {
            color: $brand-dark;
            background-color: $brand-primary;
            border-radius: 100%;
            width: fit-content;
            height: 4rem;
            width: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .content {
          all: unset;
          display: flex;
          flex-direction: row;

          .text {
            display: flex;
            flex-direction: column;
          }

          img {
            border-radius: 100%;
            aspect-ratio: 1;
            object-fit: cover;
            width: 10rem;
          }
        }

        .footer {
          display: flex;
          flex-direction: row;
          width: 100%;
          flex-wrap: wrap;

          .icon {
            max-height: 100px;
          }
        }
      }
    }
  }

  .price-bubble {
    width: 220px;
    height: 220px;
    display: flex;
    gap: 0.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $brand-dark;
    background-color: $brand-primary;
    border-radius: 100%;
    position: absolute;
    top: -2rem;
    right: -2rem;

    h2 {
      text-align: center;
      line-height: 1.5rem;
      width: 50%;
      font-size: 1.8rem;
    }
  }
}
