@import '../../styles/_variables.scss';

.action-gantt {
  position: relative;

  .action-gantt-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
    gap: 0.75rem;

    .flotilla-text-input {
      width: unset;
    }

    #action-toggle-label {
      margin-left: auto;
    }

    .slide-container {
      display: flex;
      flex-direction: column;

      input {
        accent-color: #E35924;
      }

      > label {
        font-size: 0.9rem;
        margin-left: 4px;
      }
    }

    .flotilla-selector {
      gap: 0;
    }

    #screenshot-btn {
      width: 130px;

      svg {
        width: 1rem;
        height: 1rem;
      }

      span {
        line-height: 1rem;
      }
    }
  }

  .task-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #E2E4E3;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;

    > span {
      padding: 10px;
    }
  }

  ._34SS0 {
    background-color: white !important;
    color: black;
  }

  /* Task List Column */
  ._2B2zv > div {
    transform: translateY(0.5px);
  }

  .task-list-row {
    position: relative;
    padding-left: 47px;
    border-bottom: 1px solid #ebeff2; // Grey from Gantt library
    white-space: nowrap;
    box-sizing: border-box;
    background-color: #fff;

    &:nth-child(even) {
      background-color: #f5f5f5;
    }

    .task-list-row-chevron {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }

    &.project,
    &.activity {
      display: flex;
      flex-direction: row;
      font-weight: bold;
      user-select: none;
    }

    &.project {
      padding-left: 10px;
      cursor: pointer;
    }

    &.activity {
      padding-left: 20px;
      cursor: pointer;
      font-size: 0.9em;
    }

    &.task {
      font-size: 0.9em;
    }

    .task-list-row-name {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      min-width: 100%;
      overflow: hidden;
      white-space: normal;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-right: 10px;
      height: 100%;
      min-height: 100%;
      cursor: default;

      > span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    &.task:hover {
      overflow: visible;
      white-space: unset;
      height: 16px;

      .task-list-row-name {
        position: absolute;
        background-color: inherit;
        padding-left: 47px;
        z-index: 1;
        left: 0;
        overflow: visible;
        height: unset !important;
        max-height: unset !important;
        
        > span {
          display: inline;
          -webkit-line-clamp: unset;
          -webkit-box-orient: unset;
          overflow: visible;
        }
      }
    }

    // Status right border
    &.task-list-row--status-todo > .task-list-row-name {
      border-right: 8px solid $todo-color;
    }

    &.task-list-row--status-active > .task-list-row-name {
      border-right: 8px solid $active-color;
    }

    &.task-list-row--status-completed > .task-list-row-name {
      border-right: 8px solid $completed-color;
    }

    &.task-list-row--status-overdue > .task-list-row-name {
      border-right: 8px solid $overdue-color;
    }
  }

  .calendar {
    display: flex;
    justify-content: center;
    align-items: center;

    > text {
      transform: translateY(-10px);
    }

    ._35nLX {
      fill: $color-grey-100;
    }

    /* Date Axis Milestones */
    ._2q1Kt {
      transform: translateY(-4px);
      fill: #333;
    }
  }

  .gantt-bar-tooltip {
    background-color: white;
    box-shadow: 3px 3px 15px -2px rgba(0,0,0,0.56);
    padding: 15px;
    text-align: center;
    border-radius: 0.25rem;

    .gantt-bar-tooltip-status,
    .gantt-bar-tooltip-title,
    .gantt-bar-tooltip-department {
      margin-bottom: 10px;
    }

    .gantt-bar-tooltip-status {
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }
  }

  /* Project bar */
  ._1KJ6x {
    &:has(+ ._3KcaM) {
      transform: translateY(-8px);
    }

    > rect:first-of-type {
      display: none;
    }

    > rect:nth-of-type(2) {
      height: 23px;
    }

    > rect:nth-of-type(3) {
      height: 3.5px;
      transform: translateY(14px);
    }

    > polygon {
      transform: translateY(4px);
    }

    + text {
      fill: #555;
      transform: translateY(-10px);
      font-size: 0.85em;
      font-weight: 500;

      &._3KcaM {
        transform: translateY(0px);
      }
    }
  }

  /* Bar Regular Text */
  ._3zRJQ {
    font-size: 0.85em;
    font-weight: normal;
  }

  /* Regular bar */
  ._KxSXS {
    > g > rect {
      height: 21px;
      transform: translateY(4px);
    }

    > g > rect:first-of-type {
      stroke-width: 2px;

      &::before {
        content: '';
        width: 8px;
        height: 16px;
      }
    }
  }

  /* Item Overflow Text */
  ._3KcaM {
    font-size: 0.85em;
    alignment-baseline: central;
  }
}