@import '../../../styles/_variables.scss';

h3.report-h3 {
  font-size: 1.5rem;
  line-height: 1.5rem;

  @media screen and (max-width: 1300px) {
    font-size: 1.275rem;
  }
}
