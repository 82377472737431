@import '../../../../styles/_variables.scss';

.flotilla-component-library.flotilla-modal-overlay {
  position: fixed;
  z-index: 99999999;
}

#edit-image-pop-up {
  position: relative;
  width: 100%;
  max-width: 500px;
  overflow-y: hidden;
  padding: 2rem;
  background-color: #fbfaf8;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  #actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 2rem;
  }

  input[type="file"] {
    display: none;
  }
}

