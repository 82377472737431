@import '../../../../../../styles/_variables.scss';

.cover-page {
  color: $brand-light;
  padding: 0;
  position: relative;

  .cover-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .logo {
    position: absolute;
    top: 0;
    right: 0;
    width: 10%;
    object-fit: contain;
    margin: 30px;
  }

  .company-logo {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10%;
    object-fit: contain;
    margin: 30px;
  }
}
