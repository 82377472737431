@import '../../styles/_variables.scss';

#policy-checker-summary {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 400px;

  .compliance-text {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .policy-description-modal {
    z-index: 9999;
    position: absolute;
    width: 200px;
    padding: 1rem;

    #close {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
  }

  #comply-policies {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .policy-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  #not-required-policies {
    #subtitle {
      color: $font-color-secondary;
      font-size: 0.9rem;
      margin-bottom: 1rem;
    }

    .policies {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.5rem;
    }
  }

  .flotilla-tag.policy-name {
    background-color: $color-grey-60;
    color: $brand-dark;
    max-width: 150px;
    display: block;
    cursor: pointer;

    &--required {
      background-color: $error-background;
      color: $brand-light;
      
    }

    &--conditional {
      background-color: $warning-background;
    }

    &--not-required {
      background-color: $success-background;
      color: $brand-light;
    }
  }
}