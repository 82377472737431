@import '../../../../styles/_variables.scss';

.high-level-stat {
  &.flotilla-card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    background-color: $brand-light;
    border: 1px solid $color-grey-100;
    min-height: 107px;
  }

  h1, p {
    margin: unset;
  }

  > p {
    height: 1.25rem;
  }

  p {
    display: flex;
    flex-direction: row;
    align-items: center;

    > .flotilla-tooltip {
      z-index: 1;

      > .tooltip {
        min-width: 520px;
      }
    }
  }

  #value-container {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    justify-content: flex-start;
    align-items: baseline;

    h1#value {
      font-family: $font-family;
    }

    #sub-value {
      color: $font-color-secondary;
    }

    .arrow-icon {
      border-radius: 9999px;
      padding: 0.5rem;
      height: 1.5rem;
      width: 1.5rem;
      align-self: flex-end;
      margin-left: auto;
    }

    #down-arrow {
      background-color: $success-primary;
      fill: $success-text;
    }

    #up-arrow {
      background-color: $error-primary;
      fill: $error-text;
    }
  }

  #progress-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    color: $font-color-secondary;

    #progress-text {
      font-size: 0.75rem;
    }

    progress[value] {
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      height: 0.5rem;
      color: $brand-dark;
      border: none;

      &::-webkit-progress-bar {
        background-color: $color-grey-100;
        border-radius: 1rem;
      }

      &::-webkit-progress-value {
        background-color: $brand-dark;
        border-radius: 1rem;
      }
    }
  }

  &--dark-background {
    h1, p {
      color: $brand-light;
    }

    #value-container {
      #sub-value {
        color: $color-grey-200;
      }
    }
  
    #progress-container {
      color: $color-grey-60;

      progress[value] {  
        color: $brand-light;
  
        &::-webkit-progress-bar {
          background-color: $color-grey-700;
        }
  
        &::-webkit-progress-value {
          background-color: $brand-light;
        }
      }
    }
  }
}