@import '../../../styles/_variables.scss';

#impact-areas {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  min-height: 600px;

  > header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  > main {
    all: unset;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .item-cards {
    flex: 1;
    width: 100%;
    box-sizing: border-box;
  }

  .item-cards--loading {
    flex: 1;
    width: 100%;
    min-height: 138px;

    &:first-child {
      min-height: 97px;
    }
  }

  #travel-\&-logistics {
    border-color: $impact-area-travel-primary;

    progress[value] {
      &::-webkit-progress-value {
        background-color: $impact-area-travel-primary;
      }
    }
  }

  #energy {
    border-color: $impact-area-energy-primary;

    progress[value] {
      &::-webkit-progress-value {
        background-color: $impact-area-energy-primary;
      }
    }
  }

  #facilities {
    border-color: $impact-area-facilities-primary;

    progress[value] {
      &::-webkit-progress-value {
        background-color: $impact-area-facilities-primary;
      }
    }
  }

  #supply-chain {
    border-color: $impact-area-supply-chain-primary;

    progress[value] {
      &::-webkit-progress-value {
        background-color: $impact-area-supply-chain-primary;
      }
    }
  }

  #buildings {
    border-color: $impact-area-buildings-primary;

    progress[value] {
      &::-webkit-progress-value {
        background-color: $impact-area-buildings-primary;
      }
    }
  }

  #people {
    border-color: $impact-area-people-primary;

    progress[value] {
      &::-webkit-progress-value {
        background-color: $impact-area-people-primary;
      }
    }
  }

  #governance {
    border-color: $impact-area-governance-primary;
  }

  @media screen and (min-width: $largeDesktop) {
    grid-template-columns: 1fr 1fr;
  }

  &.impact-areas--locked {
    main::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
      border-radius: 0.25rem;
      background: rgba($brand-light, 0.01);
      backdrop-filter: blur(4px);
      pointer-events: none;
    }
  }
}
