@import '../../../../styles/_variables.scss';

main#app > :nth-child(2)#new-action-page {
  padding: unset;
}

#new-action-page {
  flex: 1;
  min-width: 0;

  #save {
    width: fit-content;
    padding: 0 2rem;
  }

  main {
    margin: 0 2rem;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 2rem;

    p {
      margin: unset;
    }

    #main-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    #description-preview {
      h4 {
        margin-bottom: 0.5rem;
      }
    }

    #title {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      #type-container {
        height: 2.625rem;
        width: 2.625rem;
        padding: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: none;
      }

      #type {
        background-color: $color-grey-40;
        cursor: unset;
        pointer-events: none;
      }
    
      #title-input {
        width: 100%;
      }

      #options {
        width: 100%;
        padding: unset;

        .option {
          padding: 1rem;
        }

        hr {
          margin: 0 1rem;
        }

        hr:last-child {
          display: none;
        }
      }

      #info-message {
        width: 100%;
        max-width: unset;
      }
    }

    #children-container {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      flex-wrap: wrap;

      .action-card {
        width: 18rem;
      }
    }
  
    #meta-data {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      padding-bottom: 7.5rem;
    }

    #impact-area,
    #savings {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .flotilla-number-input .flotilla-tooltip {
      top: 4px;

      .tooltip {
        min-width: 300px;
      }
    }

    .switch-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0.25rem;
        color: $color-grey-700;

        > p {
          flex: 1;
          min-width: 0;
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        svg.icon{
          height: 1rem;
          fill: $color-grey-700;
        }
      }
    }

    #flotilla-world {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    #list-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      header {
        all: unset;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
        justify-content: space-between;
      }

      #list {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        flex-wrap: wrap;
  
        .action-card {
          width: 18rem;
        }
      }
    }
  }
}