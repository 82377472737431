@import '../../styles/_variables.scss';

.checkbox-button-container {
  position: relative;
  width: auto;
  height: auto;

  .checkbox-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    width: max-content;
    cursor: pointer;
    padding: 0.5rem 1rem;
    background-color: $color-grey-40;
    border: 1px solid transparent;
    transition: all 250ms, background 800ms;

    svg {
      height: 1.5rem;
      opacity: 0.7;
    }

    p {
      text-align: center;
      color: $font-color-secondary;
    }

    &:not(:hover) {
      box-shadow: unset;
    }

    &:hover {
      border-color: $color-grey-700;
      background-color: $color-grey-20;
    }
  }

  input {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
  }

  input:checked {
    ~.checkbox-button {
      border-color: $brand-primary;

      p,
      svg {
        color: $font-color;
        opacity: 1;
      }
    }
  }
}