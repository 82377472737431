@import '../../../styles/_variables.scss';

li.report-li {
  font-size: 1rem;
  line-height: 1.2rem;
  padding-left: 1rem;

  @media screen and (max-width: 1300px) {
    font-size: 0.85rem;
  }
}
