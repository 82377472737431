main#app > :nth-child(2)#home-page {
  padding: unset;
}

#home-page {
  flex: 1;
  min-width: 0;

  .main-content {
    margin: 0 2rem;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    row-gap: 2rem;
    column-gap: 2rem;
    align-items: flex-start;
    justify-items: stretch;

    #dashboard-customer-list {
      grid-column: auto / span 2;
      max-width: 800px;
    }
  
    @media (max-width: 1137px) {
      #dashboard-notification-list {
        grid-column: auto / span 2;
      }
    }
  }
}