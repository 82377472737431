@import '../../../../styles/_variables.scss';

article.customer-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0.25rem;
  cursor: pointer;

  .title-avatar-container {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    .title-subtitle-container {
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
  
      p {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .title {
        font-weight: 600;
      }
    }
  }

  .button-container {
    width: 134px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem
  }

  &:hover {
    background-color: transparentize($brand-primary, 0.9);
  }

  &.customer-list-item--skeleton {
    height: 44px;
    animation: skeleton-loading 1s linear infinite alternate;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: #c2cfd6;
  }
  100% {
    background-color: #f0f3f5;
  }
}