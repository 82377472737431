@import '../../../../styles/_variables.scss';

#users-page {
  > main {

    #main-content {
      margin-top: unset;
    }

    #table-container { 
      .table-section {
        padding-bottom: 10rem;
        height: 65dvh;
      }
  
      table {
        position: relative;
        width: 100%;

        thead {
          z-index: 1;

          th:first-of-type {
            position: sticky;
            left: 0;
          }
        }

        tbody {
          background-color: $brand-light;
        }
      }
    }
  }
}