@import '../../../../../styles/_variables.scss';

tr.user-list-item  {
  cursor: pointer;
  td.rating {
    text-align: center;
    padding-right: 2rem;
  }

  td#name {
    position: sticky;
    left: 0;
    background: $brand-light;
    box-sizing: border-box;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    #name-container {
      min-width: 200px;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: center;
      padding: 0.5rem 0;
      
      #crown-container {
        background-color: #FCE100;
      }
    }
  }

  td#last-active {
    > section {
      width: 200px;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: center;
      justify-content: space-between;
    }

    #more-menu {
      z-index: 99999;
      width: 200px;
      transform: translate(0%, 75%);
    }
  }

  td#department,
  td#role-string {
    text-wrap: nowrap;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}