@import '../../../../../../styles/_variables.scss';

.carbon-footprint-impact-area-page {
  padding: calc(30px + 1.25rem) calc(70px + 1.25rem);
  position: relative;

  .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding-top: 2rem;
    overflow: visible;
    gap: 2rem;

    .chart-side {
      flex: 1;
      height: 80%;
    }

    .items-side {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}
