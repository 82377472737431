@import '../../../styles/_variables.scss';

#emission-hotspots-widget {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  min-width: 150px;
  box-sizing: border-box;

  >main {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;

    header {
      grid-row: 1;
      grid-column: 1 / 4;
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
      cursor: pointer;
      align-items: flex-end;
      margin-bottom: unset;

      h3 {
        line-height: 2.25rem;
        height: 2.25rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  
    table {
      position: relative;
      width: 100%;

      #name {
        min-width: 60px;
        max-width: 20vw;
        height: 3.375rem;
      }

      .tco2e {
        article {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          gap: 0.5rem;
          
          #percent {
            color: $font-color-secondary;
            font-size: 0.75rem;
            text-align: right;
            width: 2.25rem;
          }
          
          #value {
            width: 45px;
            text-align: right;
          }
        }
      }
      
      #actions {
        padding-right: 0.25rem;

        article {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          > svg {
            padding-top: 4px;
            height: 50px;
            cursor: pointer;
  
            #actions-progress-progress-path {
              stroke: transparentize($brand-dark, 0.9);
            }
          }
        }
      }
    }
  }

  &.emission-hotspots-widget--locked {
    table::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
      border-radius: 0.25rem;
      background: rgba($brand-light, 0.01);
      backdrop-filter: blur(4px);
      pointer-events: none;
    }
  }
}

#suggested-actions-modal {
  width: 70dvw;
  max-width: unset;
  height: 80dvh;
  overflow: auto;
  padding: unset;
}