@import '../../../../../../styles/_variables.scss';

#report-baseline-year-section {
  flex: 1;
  padding-top: 2.5dvh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
  max-width: 500px;
  margin: auto;

  .options {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .checkbox {
      min-width: 150px;
      width: fit-content;
      box-sizing: border-box;
      justify-content: center;

      &--loading {
        height: 33px;
        width: 200px;
      }
    }

    &#data-periods {
      margin-top: 2rem;
    }
  }
}