@import '../../../../../styles/_variables.scss';

#card-view {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  padding-bottom: 1rem;

  .action-card,
  .action-card--loading {
    width: 18rem;
  }

  .action-card--loading {
    min-height: 9.5rem;
  }
}