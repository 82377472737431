@import '../../styles/_variables.scss';

.assigned-to-input {
  width: 100%;
  max-width: 150px;
  
  input::placeholder {
    overflow: visible;
  }

  #selected-user {
    position: relative;

    .search {
      input {
        padding: unset;
        padding-left: 3rem;
        border: unset;

        &:disabled {
          background-color: transparent;
        }
      }
    }

    .avatar {
      position: absolute;
      bottom: 0.25rem;
      left: 0.5rem;
    }
  }

  .list-container {
    min-width: 200px;
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
    display: none;
    z-index: 9;

    .user-list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
      padding: 0.5rem;
      cursor: pointer;

      >section {
        display: flex;
        flex-direction: column;

        :nth-child(2) {
          color: $font-color-secondary;
        }

      }

      &:hover {
        background-color: $color-grey-40;
      }
    }
  }

  .list-container--show {
    display: block;
  }

  .avatar {
    width: 2rem;
    min-width: 2rem;
    height: 2rem;

    span {
      font-size: 1rem;
    }
  }
}

#invite-modal {
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  box-sizing: border-box;
}