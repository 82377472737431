@import '../../../../../../styles/_variables.scss';

.comments-comments {
  padding: calc(30px + 1.25rem) calc(70px + 1.25rem);
  overflow: visible;

  .header {
    padding: 0 2rem;
    margin: 1rem 0;
    width: 100%;
  }

  .content {
    width: 100%;
    flex: 1;
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;

    .comments-container {
      flex: 1;
      overflow: visible;
    }
  }
}
