@import '../../../../../styles/_variables.scss';

main#app > :nth-child(2)#our-emissions-page {
  padding: unset;
}

#our-emissions-page {
  flex: 1;
  min-width: 0;

  #extra-content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 2rem;

    #year-selector {
      min-width: 160px;
    }

    &>div {
      label {
        all: unset
      }
    }

    .stat-label {
      text-align: right;
    }
  }

  .page-spinner {
    margin: auto;
    margin-top: 5rem;
  }

  main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 2rem;
    margin-top: 5rem;
    margin-bottom: 6rem;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: min-content;
    min-height: 70dvh;

    &::-webkit-scrollbar {
      display: none;
    }

    #quick-filters {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 1rem;

      .quick-filter {
        .quick-filter-menu-item {
          border-left: 0.5rem solid transparent;
          border-radius: 0.25rem;
  
          &--travel-\&-logistics {
            border-left-color: $impact-area-travel-background;
          }
  
          &--facilities {
            border-left-color: $impact-area-facilities-background;
          }
  
          &--supply-chain {
            border-left-color: $impact-area-supply-chain-background;
          }
  
          &--governance {
            border-left-color: $impact-area-governance-background;
          }
  
          &--buildings {
            border-left-color: $impact-area-buildings-background;
          }
  
          &--people {
            border-left-color: $impact-area-people-background;
          }
  
          &--scope-1 {
            border-left-color: $scope-1-color;
          }
  
          &--scope-2 {
            border-left-color: $scope-2-color;
          }
  
          &--scope-3 {
            border-left-color: $scope-3-color;
          }
        }
      }

      #view-selector {
        margin-left: auto;
      }
    }

    table {
      width: 100%;

      thead tr th:first-child {
        padding-left: 3.5rem;
      }

      tbody {
        tr {          
          &.row-type {

            #expand-button-placeholder {
              width: 42px;
            }

            #name {
              border-left: 0.5rem transparent solid;
            }

            &--travel-\&-logistics {
              #name {
                border-left-color: $impact-area-travel-background;
              }
            }
    
            &--facilities {
              #name {
                border-left-color: $impact-area-facilities-background;
              }
            }
    
            &--supply-chain {
              #name {
                border-left-color: $impact-area-supply-chain-background;
              }
            }
    
            &--governance {
              #name {
                border-left-color: $impact-area-governance-background;
              }
            }
    
            &--buildings {
              #name {
                border-left-color: $impact-area-buildings-background;
              }
            }
    
            &--people {
              #name {
                border-left-color: $impact-area-people-background;
              }
            }
    
            &--scope-1 {
              #name {
                border-left-color: $scope-1-color;
              }
            }
    
            &--scope-2 {
              #name {
                border-left-color: $scope-2-color;
              }
            }
    
            &--scope-3 {
              #name {
                border-left-color: $scope-3-color;
              }
            }
          }
        }
      }


      #name {
        max-width: 20vw;
        height: 3.8rem;

        button {
          min-width: 42px;
        }
      }

      .tco2e {
        article {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          gap: 0.5rem;
          
          #percent {
            color: $font-color-secondary;
            font-size: 0.75rem;
            text-align: right;
            width: 2.25rem;
          }
          
          #value {
            width: 45px;
            text-align: right;
          }
        }
      }
      
      #actions {
        padding-right: 0.25rem;

        article {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          > svg {
            padding-top: 4px;
            height: 50px;
            cursor: pointer;
  
            #actions-progress-progress-path {
              stroke: transparentize($brand-dark, 0.9);
            }
          }
        }
      }
    }
  }

  &.our-emissions-page--locked {
    main {
      position: relative;
      overflow: visible;
    }
  
    main::before {
      content: '';
      position: absolute;
      top: -2rem;
      left: -1rem;
      right: -1rem;
      bottom: 0;
      z-index: 1;
      border-radius: 0.25rem;
      background: rgba($brand-light, 0.01);
      backdrop-filter: blur(4px);
      pointer-events: none;
    }

    #locked-tooltip {
      .flotilla-card.tooltip {
        left: 0;

        p {
          font-size: 1rem;
          width: 100%;
          white-space: unset;
        }
      }
    }
  }

  table.flotilla-tree-table tbody tr.tree-level--1 td:first-child {
    margin-left: unset;
    padding-left: 2rem;
    
  }

  table.flotilla-tree-table tbody tr.tree-level--2 td:first-child {
    margin-left: unset;
    padding-left: 4rem;
    
  }

  table.flotilla-tree-table tbody tr.tree-level--3 td:first-child {
    margin-left: unset;
    padding-left: 9.125rem;
  }

  table.flotilla-tree-table tbody tr td:first-child:not(:has(button)) {
    margin-left: unset;
  }
}