@import '../../../../../../styles/_variables.scss';

.graph-comments {
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 3rem;
  padding-top: 100px;
  gap: 2rem;

  .comments-container {
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    ol {
      list-style: none;
    }

    .comments-content {
      h2 {
        font-size: 1.25rem;
        line-height: 1.25rem;
        margin-top: 1rem;
      }

      p,
      li {
        font-size: 1rem;
        line-height: 1rem;
      }

      li {
        padding-left: 1.25rem;
        padding-top: 1rem;
      }
    }

    @media screen and (max-width: 1300px) {
      gap: 1rem;
    }
  }

  .graph-container {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    .graph {
      object-fit: contain;
      width: 100%;
    }
  }

  &.graph-comments--reversed {
    flex-direction: row-reverse;
  }
}
