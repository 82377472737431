.final-page {
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0;

  .final-page-logo {
    width: 40%;
    z-index: 2;
  }

  .slogan {
    color: #fbfaf8;
    z-index: 2;
  }

  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 17%;
    color: #fbfaf8;
  }
}
