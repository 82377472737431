@import '../../../../../../styles/_variables.scss';

#add-report-page-popup.editor-container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: auto;
  background: #011e2650;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  button {
    min-height: 42px;
  }

  #add-report-page-popup {
    position: relative;
    width: 100%;
    max-width: 1000px;
    overflow-y: hidden;
    padding: 2rem;
    background-color: #fbfaf8;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    .close-icon {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }

    main {
      all: unset;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      overflow: auto;

      #page-image-input {
        display: none;
      }
    }
  }
}
