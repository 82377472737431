@import '../../../../../../styles/_variables.scss';

#settings-tab-content {
  display: flex;
  flex-direction: column;
  width: 100%;

  > header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1.5rem;
    position: sticky;
    top: 5rem;
    z-index: 9999;
    background-color: $color-grey-100;

    h3 {
      display: block;
      text-wrap: nowrap;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    button {
      width: 100px;
    }
  }

  .accordion-container {
    width: 100%;
    box-sizing: border-box;

    #accordion-header {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      padding: 1.5rem;

    }

    #accordion-content {
      padding: 1rem 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      #question-group-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        >h4 {
          font-weight: 600;
        }

        #questions-container {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      }
    }

    &.location-accordion--color {
      #accordion-header {
        padding-left: 1rem;
      }

      #accordion-content {
        padding-left: 1rem;
      }
    }
  }
}