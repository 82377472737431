@import '../../../../../../styles/_variables.scss';

#financials-tab-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 500px;

  .flotilla-tooltip {
    margin-top: -1rem;
  }

  #additional-info-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
      color: $color-grey-800;
    }
  }
}