@import '../../../../styles/_variables.scss';

main#app > :nth-child(2)#data-detail-page {
  padding: unset;
}

#data-detail-page {
  flex: 1;
  min-width: 0;

  h4 {
    font-weight: 300;
  }

  #actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    #submit-data-period {
      padding-bottom: 0.125rem;
      padding-left: 0.5rem;
      padding-right: 0.75rem;
      width: auto;
      flex-direction: row-reverse;

      #label {
        font-weight: 600;
        font-size: 0.8rem;
      }
    }

    #changes-indicator {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.25rem;
      cursor: default;

      #indicator {
        display: block;
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 999999px;
        background-color: $success-primary;
      }

      &.changes-indictor--pending {
        #indicator {
          background-color: $error-primary;
        }
      }
    }
  }

  > main {
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 2rem;
    padding-bottom: 4rem;
    overflow-x: hidden;

    #data-period-tab,
    #settings-tab {
      padding: unset;
    }
  }

  #leave-modal {
    h4 {
      max-width: 95%;
    }
  
    #actions {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;
    }
  }

  details[open] .flotilla-accordion-content,
  .data-period-accordion,
  .data-period-activity  {
    overflow: initial;
  }

  #quick-filters {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;

    .scope-tag {
      cursor: pointer;
    }

    .compliance-tag {
      cursor: pointer;
  
      &.flotilla-tag--solid {
        color: $brand-dark;
        background-color: $color-grey-80;
      }

      &.flotilla-tag--outline {
        border-color: $color-grey-700;
      }
    }
  }

  #warning-notes {
    width: 100%;
    max-width: unset;
    cursor: pointer;
  }
}