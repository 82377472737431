@import '../../../styles/_variables.scss';

#actions-table-widget {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-height: 120px;
  box-sizing: border-box;
  padding: 1rem 2rem;

  #actions {
    height: 80%;

    thead {
      th {
        section #title {
          height: 2rem;
          border-radius: 2rem;
          padding: 0.25rem 0.75rem;
          box-sizing: border-box;
          flex: unset;
        }
      }
    
      th:nth-child(2) {
        section #title {
          background-color: #E2E4E3;
          border: 1px solid $brand-dark;
        }
      }

      th:nth-child(3) {
        section #title {
          background-color: #E35924;
          color: $brand-light;
        }
      }

      th:nth-child(4) {
        section #title {
          background-color: #CE3636;
          color: $brand-light;
        }
      }
      
      th:nth-child(5) {
        section #title {
          background-color: #46B05D;
          color: $brand-light;
        }
      }
    }

    tbody {
      td {
        padding: 1rem 0.75rem;
        cursor: pointer;

        &:first-of-type {
          cursor: default;
        }
      }
    }
  }
}

#actions-table-widget--loading {
  width: 100%;
  min-height: 250px;
  border-radius: 0.25rem;
  animation: skeleton-loading 1s linear infinite alternate;
}

header {
  display: flex;
  flex-direction: row;
}

#view-selector {
  margin-left: auto;
}

@keyframes skeleton-loading {
  0% {
    background-color: #c2cfd6;
  }
  100% {
    background-color: #f0f3f5;
  }
}
