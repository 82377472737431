@import '../../../../../styles/_variables.scss';

#create-question-modal {
    width: 50vw;
    max-width: 700px;
    min-width: 300px;
    background-color: $brand-light;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  
    h2 {
      margin: 0;
    }
  
    main {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      height: min-content;
      margin: 0;
    }
  
    .button-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  
      button {
        width: min-content;
        padding: 0 2rem;
      }
    }
}