#ask-flotilla-page {
  flex: 1;
  padding: 1rem 2rem;
  padding-top: 3rem;
  overflow-y: auto;
  overflow-x: hidden;

  > header {
    margin-bottom: 3rem;
  }

  > main {
    all: unset;
    width: 100%;
    height: calc(100% - 11rem);
    display: flex;
    flex-direction: row;
    gap: 2rem;
  
    #virtual-consultant {
      flex: 3;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      #ribbo-widget-iframe {
        border: unset;
        width: 100%;
        flex: 1;
      }
    }

    #contact-info {
      flex: 2;
      width: 100%;
      height: 100%;
    }
  }
}