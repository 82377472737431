@import "../../styles/_variables.scss";

.flotilla-required-marker {
  display: inline-block;
  min-width: 0.5rem;
  max-width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.75rem;
  background-color: $error-background;
  margin-left: 0.5rem;
  margin-bottom: 0.125rem;
}