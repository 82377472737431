@import '../../../../../../styles/_variables.scss';

#net-zero-year-section {
  flex: 1;
  padding-top: 2.5dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  max-width: 500px;
  margin: auto;

  #initial-options {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    #options {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .checkbox {
        width: 100%;
        box-sizing: border-box;
      }
    }
  }

  #more-options {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
}