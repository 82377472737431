@import '../../../../styles/_variables.scss';

main#app > :nth-child(2)#project-plan-page {
  padding: unset;
}

#project-plan-page {
  flex: 1;
  min-width: 0;
  box-sizing: border-box;
  padding: 1rem 2rem;
  padding-top: 3rem;

  #extra-content {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    #actions-completed-container {
      min-width: 150px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0.25rem;

      h3, p {
        margin: unset;
        padding: unset;
      }

      p {
        color: #4C6065;
      }
    }
  }

  > main {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0 2rem;
    margin-top: 3rem;
    height: unset;

    .flotilla-column-options {
      z-index: 99999;
      padding-bottom: 120px;
    }

    .quick-filter {
      .quick-filter-menu-item {
        border-left: 0.5rem solid transparent;
        border-radius: 0.25rem;

        &--travel-\&-logistics {
          border-left-color: $impact-area-travel-background;
        }

        &--facilities {
          border-left-color: $impact-area-facilities-background;
        }

        &--supply-chain {
          border-left-color: $impact-area-supply-chain-background;
        }

        &--governance {
          border-left-color: $impact-area-governance-background;
        }

        &--buildings {
          border-left-color: $impact-area-buildings-background;
        }

        &--people {
          border-left-color: $impact-area-people-background;
        }
      }
    }

    #view {
      >main {
        margin-top: 0.5rem;
        height: calc(100dvh - 200px);
        overflow-y: auto;
      }
    }
  }

  #download-options {
    z-index: 9999;
  }
}