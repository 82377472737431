@import '../../../../styles/_variables.scss';

.report-editor-text-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  background-color: transparentize($brand-dark, 0.95);

  &.report-editor-text-container--show-border {
    border-left: 0.5rem solid #011e26;
  }

  @media screen and (max-width: 1300px) {
    gap: 0.2125rem;
  }
}
