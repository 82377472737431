@import '../../../../../styles/_variables.scss';

tr.customer-list-item  {
  td#name {
    a {
      cursor: pointer;
      width: 100%;
    }
  }

  td#email {
    > section {
      flex: 1;
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
      align-items: center;

      a {
        display: block;
        max-width: 100px;
        text-wrap: nowrap;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      #more-button {
        margin-left: auto;
      }

      #more-menu {
        z-index: 99999;
        width: 220px;
        transform: translate(-4rem, 7rem);
      }
    }
  }
}