@import '../../../../../styles/_variables.scss';

#board-view {
  min-height: 60dvh;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  background-color: $brand-light;
    
  .status-column {
    flex: 1;
    max-width: 19vw;
    background-color: $color-grey-20;
    border-radius: 0.25rem;

    > header {
      position: sticky;
      top: 0;
      box-sizing: border-box;
      width: 100%;
      padding: 0.75rem;
      border-radius: 0.25rem;
      background-color: $color-grey-100;
      z-index: 1;

      :nth-child(1) {
        flex: 1;
      }
  
      :nth-child(2) {
        color: $font-color-secondary;
      }
    }

    &#overdue-column >header {
      border-left: 0.5rem solid $overdue-color;
    }
  
    &#todo-column >header {
      border-left: 0.5rem solid $todo-color;
    }

    &#active-column >header {
      border-left: 0.5rem solid $active-color;
    }

    &#completed-column >header {
      border-left: 0.5rem solid $completed-color;
    }

    > main {
      padding: 1rem 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .action {
        cursor: pointer;
        padding: 0.75rem;
        background-color: $color-grey-60;

        &:active {
          opacity: 0.8;
        }
      }

      .action--loading {
        height: 144px;
      }
    }
  }
}