@import '../../../styles/_variables.scss';

p.report-p {
  font-size: 1rem;
  line-height: 1.2rem;
  margin: 0;

  @media screen and (max-width: 1300px) {
    font-size: 0.85rem;
  }
}
