@import '../../../../../../styles/_variables.scss';

.image-full-page {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: calc(30px + 1.25rem) calc(70px + 1.25rem);
  gap: 1rem;

  .image-container {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .image-full-page-image {
      width: 99%;
      margin: auto;
    }
  
    #edit-icon {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
    }

    &:hover {
      background-color: $color-grey-40;

      #edit-icon {
        display: block;
      }
    }
  }
}
