@import '../../../../styles/_variables.scss';

main#app > :nth-child(2)#survey-detail-page {
  padding: unset;
}

#survey-detail-page {
  flex: 1;
  padding: 1rem 2rem;
  padding-bottom: 5rem;
  overflow-y: auto;
  overflow-x: hidden;

  #link-container {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.125rem;
    cursor: pointer;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
    padding-top: 2rem;

    #survey-options {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .survey-options-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        align-items: flex-start;

        .input-container {
          flex: 1;
          min-width: 200px;
          max-width: 250px;
        }
      }

      .input--loading {
        margin-top: 0.5rem;
        height: 2.5rem;
      }
    }

    .stat {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      label {
        color: $font-color-secondary;
      }

      p {
        line-height: 2.25rem;
        font-size: 1.125rem;
      }
    }

    #company-questions-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      header {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
  
        h3.title {
          margin: 0;
        }
  
        p.subtitle {
          margin: 0;
        }
      }


      .company-question-container {
        display: flex;
        flex-direction: column;

        header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;

          h4 {
            margin: 0;
          }
        }

        main {
          all: unset;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
          row-gap: 0.5rem;
          column-gap: 2rem;
        }
      }

      #add-question {
        align-self: flex-end;
        width: 100%;
        max-width: 300px;
      }
    }
  }
}