.report-page {
  &.report-page--skeleton {
    border: unset;
    box-shadow: none;
    animation: skeleton-loading 1s linear infinite alternate;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: #c2cfd6;
  }
  100% {
    background-color: #f0f3f5;
  }
}