@import '../../../../../styles/_variables.scss';

#conversion-factor-table-view {
  #table-container { 
    .table-section {
      height: 85dvh;
      padding-bottom: 15rem;
      box-sizing: border-box;
    }
  }

  table {
    width: 100%;

    thead {
      z-index: 1;
    }

    #more-container {
      position: relative;
      overflow: visible;

      section {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: visible;
        gap: 0.25rem;
        
        #conversion-factor-menu {
          min-width: 150px;
          z-index: 999999;
          left: -9rem;
          top: 100%;
        }
      }
    }
  }
}