@import '../../../styles/_variables.scss';

#progress-stats {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  #stats {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .progress-stat {
      flex-grow: 1;
      width: 30%;
      min-width: calc(306px - 2rem);
    }

    .progress-stat--loading {
      flex: 1;
      padding: 1rem;
      min-width: calc(306px - 2rem);
      min-height: 107px;
    }
  }

  &.progress-stats--locked {
    #stats::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
      border-radius: 0.25rem;
      background: rgba($brand-light, 0.01);
      backdrop-filter: blur(4px);
      pointer-events: none;
    }
  }

  .flotilla-tooltip > .tooltip {
    width: 400px;
    min-width: unset;
    left: 70px;
  }
}
