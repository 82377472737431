@import '../../../styles/_variables.scss';

.side-nav-item {

  all: unset;
  box-sizing: border-box;
  width: 100%;
  height: 2.5rem;
  min-height: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  cursor: pointer;
  border-radius: 0.25rem;
  padding: 0 0.25rem;
  padding-right: 0.75rem;
  color: transparentize($brand-dark, 0.2);

  &:not(.side-nav-item--has-icon) {
    padding: 0 0.75rem;
  }

  &.side-nav-item--selected {
    background-color: transparentize($brand-dark, 0.95);
    color: $brand-dark;

    #icon-container {
      padding: 0.5rem;
      background-color: $brand-primary;
      border-radius: 2rem;

      .icon {
        width: 1rem;
        height: 1rem;
        opacity: 1;
      }
    }
  }

  #icon-container {
    padding: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      width: 1.25rem;
      height: 1.25rem;
      aspect-ratio: 1;
      opacity: 0.8;
    }
  }

  .label {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.side-nav-item--no-link {
    cursor: unset;
  }

  &:hover {
    color: transparentize($brand-dark, 0.2);
  }

  &:not(.side-nav-item--no-link, .side-nav-item--locked):hover {
    background-color: transparentize($brand-dark, 0.9);
    color: unset;
  }

  &.side-nav-item--locked {
    cursor: default;
  }
}

.side-nav--closed {
  .side-nav-item {
    &.side-nav-item--selected {
      background-color: transparent;

      &:hover {
        background-color: transparentize($brand-dark, 0.9);
      }
    }
  }
}