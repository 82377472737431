#data-collection-upload-page {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  &>header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;

    h1 {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    button {
      width: max-content;
      padding: 0 2rem;
    }
  }

  main {
    all: unset;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    #data-collection-input {
      display: none;
    }
  }
}