@import '../../styles/_variables.scss';

.add-tag-container {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 27px;
  border-radius: 999999px;
  background-color: $color-grey-100;
  transition: all linear 300ms;

  #add-tag {
    height: 27px;
    width: 27px;
    border-radius: 999999px;
    padding: 0.375rem;
  }

  #edit-tag {
    height: 27px;
    min-width: 200px;
    border-radius: 99999px;
    background-color: $color-grey-20;
    border-width: 0.125rem;
    border-color: $color-grey-100;
  }

  #edit-tag-suggestions {
    top: 100%;
    width: 100%;
    max-width: 300px;
    z-index: 999;

    .suggested-item {
      padding: 0.25rem 1rem;
    }    
  }
}
