@import '../../../styles/_variables.scss';

#recommend-action-modal {
  min-width: 500px;
  width: 50%;
  min-height: 350px;
  max-height: 70dvh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  > header {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    p {
      color: $font-color-secondary;
    }
  }

  > main {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: min-content;
    margin: 0;

    #selected-companies {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      flex-wrap: wrap;

      .company {
        height: 26px;
        background-color: $color-grey-60;
        color: $color-grey-800;
        gap: 0.5rem;
        cursor: pointer;
      }
    }
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      width: min-content;
      padding: 0 2rem;
    }

    &#option-actions {
      justify-content: flex-end;

      button {
        height: 2rem;
        padding: 0.5rem 1rem;
        width: fit-content;
        font-size: 0.75rem;
      }
    }
  }
}
