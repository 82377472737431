@import '../../../../../../../styles/_variables.scss';

.contents-option {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.25rem;
  gap: 0.5rem;

  span {
    color: #011e26;
    font-size: 1rem;
  }

  .title {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  #delete {
    padding: 0;
    height: 1rem;
    width: 1rem;
  }

  .hidden-btn {
    visibility: hidden;
  }

  &:hover {
    background-color: transparentize($brand-dark, 0.9);
  }

  &.contents-option--active {
    background-color: $brand-dark;

    span {
      color: $brand-light;
    }
  }
}
