@import '../../../../../../styles/_variables.scss';

.contents-sidebar {
  all: unset;
  position: fixed;
  top: 0;
  right: 0;
  height: 100dvh;
  width: 33%;
  max-width: 400px;
  background-color: $brand-light;
  box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.1);
  transition: width 1s;
  z-index: 99999999;

  li {
    all: unset;
  }

  &.contents-sidebar--hide {
    width: 0px;
  }

  .contents-container {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  #toggle {
    all: unset;
    height: 65px;
    width: 65px;
    box-sizing: border-box;
    padding: 0.5rem;
    position: absolute;
    top: calc(50% - 65px);
    left: -65px;
    background-color: $brand-light;
    border-radius: 0.25rem 0 0 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $brand-dark;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    #active-page-number {
      position: absolute;
      bottom: 0.5rem;
      margin: 0;
      font-size: 0.6rem;
    }
  }
}
