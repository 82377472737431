@import '../../../../../../../styles/_variables.scss';

.checklist-item {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  #content {
    flex: 1;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    p {
      flex: 1;
    }

    #user {
      color: $font-color-secondary;
      font-size: 0.75rem;
    }
  }

  #delete {
    margin-right: 2.5rem;
  }

  &.checklist-item--new {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
