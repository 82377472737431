@import '../../../styles/_variables.scss';

main#app > :nth-child(2)#company-home-page {
  padding: unset;
}

#company-home-page {
  position: relative;
  flex: 1;
  min-width: 0;
  padding: 0 !important;
  padding-bottom: 0 !important;
  
  > #relative-wrapper {
    position: relative;
    padding: 3rem 2rem;
    padding-top: 1rem;
    padding-bottom: 5rem;

    > main {
      all: unset;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
      max-width: calc(1500px - 4rem);

      > section {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      > section:last-child {
        flex: 3;
      }

      #image {
        flex: 1;
        background-image: url('../../../assets/images/CityLiving.jpg');
        object-fit: cover;
        min-height: 250px;
        max-height: 776px;
      }

      #emission-hotspots-widget .suggested-actions-emission-hotspots {
        height: unset;
        min-height: 100%;
      }

      &:has(#second-column > #emission-hotspots-widget > #suggested-actions-view) {
        #emission-hotspots-widget > main,
        #first-column {
          display: none;
        }
      }
    }
  }

  &.reduction-plan-dashboard {
    > #relative-wrapper {
      > main {
        > section {
          flex: 2;
        }

        > section:last-child {
          flex: 1;

          #tasks {
            height: 100%;
          }
        }
      }
    }
  }
}