@import '../../styles/_variables.scss';

.flotilla-modal-overlay {
  z-index: 1;
}

#edit-targets-modal {
  width: 50vw;
  max-width: 700px;
  min-width: 300px;
  max-height: 85vh;
  overflow: auto;
  background-color: $brand-light;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  h2 {
    margin: 0;
  }

  #reduction-chart > div {
    height: 250px !important;
  }

  .flotilla-skeleton {
    min-height: 550px;
  }

  footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      width: min-content;
      padding: 0 2rem;
    }
  }

  #close-button {
    z-index: 9;
  }
}