@import '../../../styles/_variables.scss';

.flotilla-modal-overlay {
  z-index: 1;
}

#add-action-modal {
  width: 50vw;
  max-width: 700px;
  min-width: 300px;
  background-color: $brand-light;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  > header{ 
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    p {
      color: $font-color-secondary;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: min-content;
    margin: 0;

    .switch-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      width: min-content;
      padding: 0 2rem;
    }
  }

  #locations-selector-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .location-option {
    display: flex;
    flex-direction: row;
    align-items: center;

    #name {
      flex: 1;
    }
  
    .est-savings {
      display: flex;
      flex-direction: column;
      align-items: center;

      #value {
        font-size: 0.75rem;
      }

      #label {
        font-size: 0.5rem;
      }
    }

    .checked {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5rem;
    }
  }
}
