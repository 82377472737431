@import '../../../../styles/_variables.scss';

main#app > :nth-child(2)#action-detail-page {
  padding: unset;
}

#action-detail-page {
  flex: 1;
  min-width: 0;

  >.flotilla-header {
    .assigned .list-container--show {
      position: absolute;
      right: -1.5rem;
    }

    #sticky-content > p,
    h3 {
      display: flex;
      align-items: center;

      #quick-win {
        margin-right: 0.5rem;
      }
    }

    .action-buttons {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
  
      .list-item-menu {
        right: 0;
        top: 80%;
      }
    }
  }

  #action-buttons {
    position: absolute;
    top: 3rem;
    right: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;

    #more-actions-menu {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 9;
    }
  }

  #tags-container {
    max-width: 100%;
    min-width: 0;
    min-height: 27px;
    flex: 1;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0 2rem;
    box-sizing: border-box;

    #tags {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      overflow-x: scroll;
      scrollbar-width: none;
    
      &::-webkit-scrollbar {
        display: none;
      }
    
      #tags-scroll {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
    
        #priority-tag,
        .tag {
          width: fit-content;
          height: 27px;
        }
      
        .tag {
          background-color: $color-grey-100;
          color: $brand-dark;
          cursor: pointer;
        }
  
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .list-container--show {
      z-index: 999;
    }
  }

  > main {
    display: flex;
    flex-direction: row;
    margin: 1rem 2rem;
    gap: 2rem;

    .action-updater {
      order: 2;
      flex: 1;
      min-width: 300px;
      max-width: 500px;
      min-height: 300px;
      height: min-content;
    }

    #tabs {
      flex: 2;
      min-height: 75dvh;
    }
  }

  #tabs {
    .tab-selector {
      position: sticky;
      top: 75px;
      z-index: 99;
    }

    .tab-container {
      flex: 1;
      overflow-x: auto;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }

    #checklist-tab {
      overflow-x: unset;
      overflow: visible;
    }
  }

  #detail-tab {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  #support-tab {
    padding-top: 1rem;

    #document {
      width: 100%;
      height: 100vh;
    }
  }

  .tab-selector {
    background-color: $color-grey-40;
  }
    
  @media screen and (max-width: $tabletLandscape) {
    #tabs {
      width: 100%;
    }
  }
}
