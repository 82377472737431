@import '../../../styles/_variables.scss';

.flotilla-modal-overlay {
  z-index: 1;
}

#save-changes-modal {
  h4 {
    max-width: 95%;
  }

  .button-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
  }
}