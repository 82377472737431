@import "../../styles/_variables.scss";

.flotilla-tag.scope-tag {
  &.flotilla-tag--solid {
    height: min-content;
    display: block;
    background-color: $color-grey-100;
    color: $color-grey-900;

    &.scope-tag--scope-1 {
      background-color: $scope-1-color;
      color: $brand-light;
    }
    
    &.scope-tag--scope-2 {
      background-color: $scope-2-color;
    }
    
    &.scope-tag--scope-3 {
      background-color: $scope-3-color;
      color: $brand-light;
    }
  }
}
