@import '../../../../../styles/_variables.scss';

.survey-list-item {
  p {
    margin: 0;
  }

  .type-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    width: 100%;
  }

  .flotilla-tag.type {
    background-color: transparentize($brand-dark, 0.9);
    border-radius: 50px;
    padding: 0 12px;
    width: fit-content;
    font-size: 0.75rem;
    color: $brand-dark;

    &.error {
      background-color: transparentize($error-primary, 0.7);
    }

    &.success {
      background-color: $success-primary;
      color: $success-text;
    }
  }

  .link-and-type-cell {
    max-width: 200px;
    #link-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .link {
        position: relative;
        cursor: pointer;
        width: 100%;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 6px;

        &.blur::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 9;
          border-radius: 0.25rem;
          background: rgba($brand-light, 0.01);
          backdrop-filter: blur(4px);
          pointer-events: none;
        }
      }
  
      .copy-icon {
        width: 1.2rem;
        cursor: pointer;
      }
    }
  }

  #response-rate {
    #response-rate-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      
      > svg {
        width: 50px;
        height: 50px;
      }

      #email-button,
      #download-button {
        padding: 0 1rem;
      }
    }
  }
}
