#iema-page {
  flex: 1;
  padding: 1rem 2rem;
  padding-top: 3rem;
  overflow-y: auto;
  overflow-x: hidden;

  > header {
    margin-bottom: 3rem;
  }

  > iframe {
    flex: 1;
    min-height: 80vh;
    width: 100%;
  }
}