@import "../../styles/_variables.scss";

.flotilla-tag.active-status-tag {
  &.flotilla-tag--solid {
    height: min-content;
    display: block;

    &.active-status-tag--active {
      border-color: $success-primary;
      background-color: $success-primary;
      color: $success-text;
    }
    
    &.active-status-tag--inactive {
      border-color: $warning-primary;
      background-color: transparent;
      color: $brand-dark;
    }
  }
}
