@import "../../styles/_variables.scss";

.report-status-check {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .check-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    .check-header {
      padding: unset;
      border: unset;
      background-color: unset;
      padding-right: 1.5rem;

      #chevron {
        right: 0;
      }

      &--no-detail {
        #chevron {
          display: none;
        }
      }
    }

    .text {
      .subtitle {
        color: $font-color-secondary;
        font-size: 0.75rem;
      }
    }
  }
}
