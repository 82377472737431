@import "./_variables";

body {
  font-size: $font-size;
  font-family: $font-family;
  font-weight: $font-weight;
  color: $font-color;
  background-color: $brand-light;
  letter-spacing: $letter-spacing;

  // h1 {
  //   line-height: 3.5rem !important;
  // }

  // h1, h2, h3, h4 {
  //   margin: 0;
  // }

  // p,
  // span {
  //   line-height: 1.2rem;
  // }

  // th {
  //   font-weight: $font-weight;
  // }

  a {
    all: unset;
    cursor: pointer;
    text-decoration: underline;
    color: $brand-primary;

    &:hover {
      color: $brand-primary-light;
    }
  }
}

iframe {
    border: none;
}