@import "../../styles/styles.scss";

.copy-btn {
    position: relative;

    p {
        display: none;
        position: absolute;
        top: 85%;
        right: -5%;
        padding: 0.3rem 0.6rem;
        white-space: nowrap;
        font-size: 0.8rem;
        z-index: 99999999;
    }

    &:hover p {
        display: block;
    }
}