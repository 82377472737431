@import '../../../../../../styles/_variables.scss';

#location-address-section {
  flex: 1;
  padding-top: 2.5dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  max-width: 500px;
  margin: auto;

  #location-address {
    width: 100%;
    max-width: 500px;

    #country {
      display: none;
    }
  }

  .country-select {
    transform: translateY(-2rem);
  }
}