@import '../../../../../styles/_variables.scss';

#project-plan-page {
  > main {
    #table-container {
      overflow-x: auto;
      height: calc(100dvh - 200px);

      table {
        width: 100%;

        thead {
          z-index: 1;

          th:first-of-type {
            position: sticky;
            left: 0;
          }
        }

        tbody {
          background-color: $brand-light;
        }
      }
    }
    
    #no-actions-comment {
      width: 100%;
      max-width: 500px;
      margin: 5rem auto;

      h2 {
        font-family: $font-family;
      }
    }
  }
}