@import '../../../../../../styles/_variables.scss';

.project-plan-item {
  td.rating {
    text-align: center;
    padding-right: 2rem;
  }

  td {
    .date-input {
      input {
        cursor: pointer;
        border: unset;
        padding: unset;
      }
    }
  }

  #name {
    position: sticky;
    left: 0;
    background: $brand-light;
    box-sizing: border-box;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-radius: 0.25rem;
    width: 100%;
    max-width: 30vw;
    height: 100%;
    padding: unset;

    > article {
      border-radius: 0.25rem;
      border-left: 0.5rem solid $brand-primary;
      padding: 0.25rem 0.75rem;
    }

    &.action--travel-\&-logistics > article {
      border-color: $impact-area-travel-background;
    }

    &.action--energy > article {
      border-color: $impact-area-energy-background;
    }

    &.action--facilities > article {
      border-color: $impact-area-facilities-background;
    }

    &.action--supply-chain > article {
      border-color: $impact-area-supply-chain-background;
    }

    &.action--governance > article {
      border-color: $impact-area-governance-background;
    }

    &.action--buildings > article {
      border-color: $impact-area-buildings-background;
    }

    &.action--people > article {
      border-color: $impact-area-people-background;
    }

    #quick-win {
      height: 0.75rem;
      fill: $color-grey-700;
      margin-right: 0.25rem;
    }

    button {
      min-width: 42px;
    }

    article {
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      cursor: pointer;

      p, a {
        margin: 0;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      #tag-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 0.25rem;

        .tag {
          display: block;
          background-color: $color-grey-100;
          color: $color-grey-900;

          #quick-win {
            transform: translateX(0.1rem);
          }
        }
      }
    }
  }

  #actions {
    padding: unset;
    padding-top: 0;
    padding-bottom: 0;

    svg {
      padding-top: 4px;
      height: 50px;

      #actions-progress-progress-path {
        stroke: transparentize($brand-dark, 0.9);
      }
    }
  }
}