@import '../../../../../../styles/_variables.scss';

#data-period-section {
  flex: 1;
  padding-top: 2.5dvh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
  max-width: 500px;
  margin: auto;

  .activity-selector {
    width: 100%;
  }
}