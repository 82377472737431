@import '../../../../../../styles/_variables.scss';

#reduction-target-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 600px;
  margin: auto;

  >header {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 4rem;
    padding-right: 4rem;

    >section {
      width: 10rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        color: $font-color-secondary;
        font-size: 0.75rem;
      }
    }
  }

  .sbt-warning {
    max-width: unset;
    width: unset;

    #icon {
      margin: auto 1rem auto 0;
    }
  }

  .reduction-target-input {
    padding: 0.5rem 1rem;
    padding-right: 2rem;
    box-shadow: none;
    border-left: 0.5rem solid $brand-primary;
    display: flex;
    flex-direction: row;
    gap: 4rem;
    align-items: center;

    &#scope-1 {
      border-left-color: $scope-1-color;
    }

    &#scope-2 {
      border-left-color: $scope-2-color;
    }

    &#scope-3 {
      border-left-color: $scope-3-color;
    }

    h4 {
      width: 100px;
    }

    .target {
      width: 10rem;
      text-align: center;
    }

    .input-target {
      input {
        padding: unset;
        text-align: right;
        width: unset;
        pointer-events: none;
      }

      .input-item {
        font-size: 1.5rem;
        padding-right: 0.125rem;
      }
    }
  }

  #reduction-chart {
    margin-top: 2.5dvh;
    width: 120%;
    min-height: 250px;
    max-height: 300px;
  }
}