@import "../../styles/_variables.scss";

.flotilla-tag.status-tag {
  background-color: $color-grey-80;
  color: $color-grey-800;

  &.status-tag--active {
    background-color: $brand-primary;
  }
  
  &.status-tag--completed {
    background-color: $success-primary;
    color: $success-text;
  }
  
  &.status-tag--to-do {
    color: $brand-dark;
    background-color: transparentize($brand-dark, 0.9);
  }
  
  &.status-tag--overdue,
  &.status-tag--disabled {
    background-color: $error-primary;
    color: $error-text;
  }
}
