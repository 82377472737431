@import "../../styles/_variables.scss";

#action-card {
  position: relative;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  height: 9.5rem;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  border-left: 0.5rem solid $brand-primary;

  &.action-card--reduced-detail {
    height: 4.5rem;
  }

  &.action-card--travel-\&-logistics {
    border-color: $impact-area-travel-background;
  }

  &.action-card--energy {
    border-color: $impact-area-energy-background;
  }

  &.action-card--facilities {
    border-color: $impact-area-facilities-background;
  }

  &.action-card--supply-chain {
    border-color: $impact-area-supply-chain-background;
  }

  &.action-card--governance {
    border-color: $impact-area-governance-background;
  }

  &.action-card--buildings {
    border-color: $impact-area-buildings-background;
  }

  &.action-card--people {
    border-color: $impact-area-people-background;
  }

  p {
    margin: unset;
  }

  header {
    margin: unset;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.25rem;

    p {
      flex: 1;
      height: 36px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    #type {
      background-color: $color-grey-100;
      border-radius: 0.25rem;
      padding: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 1rem;
        width: 1rem;
      }
    }

    #close-button {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
    }
  }

  #tags {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    .tag,
    .department-tag {
      background-color: $color-grey-100;
      color: $color-grey-900;
    }
  }

  #stats {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    align-self: flex-end;

    hr {
      height: 100%;
      border-left: 0.125rem solid $color-grey-200;
    }

    .stat {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.25rem;
      
      svg {
        height: 1rem;
        width: 1rem;
      }

      p {
        font-size: 0.75rem;
      }
    }
  }
}