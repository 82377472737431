@import '../../../../styles/_variables.scss';

main#app > :nth-child(2)#action-library-page {
  padding: unset;
}

#action-library-page {
  flex: 1;
  min-width: 0;

  .page-loader {
    margin: 5vh auto;
  }

  .flotilla-column-options {
    z-index: 99999;
    padding-bottom: 120px;
  }

  #create-btn {
    span {
      line-height: unset;
    }

    svg {
      width: 1.2rem;
      height: 1.2rem;
      stroke-width: 1rem;
    }
  }

  #subtitle {
    margin-right: 125px;
  }

  > header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    #extra-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      &>div {
        label {
          all: unset
        }
      }

      #search {
        height: 42px;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
  
        #search-input {
          height: 42px;
        }

        #search-button {
          position: absolute;
          right: 0;
        }
      }
    }
  }

  > main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 2rem;
    margin-top: 2rem;
    height: fit-content;

    .quick-filter {
      .quick-filter-menu-item {
        border-left: 0.5rem solid transparent;
        border-radius: 0.25rem;

        &--travel-\&-logistics {
          border-left-color: $impact-area-travel-background;
        }

        &--facilities {
          border-left-color: $impact-area-facilities-background;
        }

        &--supply-chain {
          border-left-color: $impact-area-supply-chain-background;
        }

        &--governance {
          border-left-color: $impact-area-governance-background;
        }

        &--buildings {
          border-left-color: $impact-area-buildings-background;
        }

        &--people {
          border-left-color: $impact-area-people-background;
        }
      }
    }

    table {
      display: block;
      max-width: fit-content;
      overflow-x: auto;
      height: calc(100dvh - 190px);
      padding-bottom: 15rem;

      thead {
        z-index: 1;

        th:first-of-type {
          position: sticky;
          left: 0;
        }

        th .tooltip {
          min-width: 320px;
          translate: -50%;

          p {
            white-space: pre-wrap;
          }
        }
      }

      td.rating {
        text-align: center;
        padding-right: 2rem;
      }
      
      td:last-of-type {
        padding-right: 2.5rem;
      }

      tbody {
        width: 100%;

        tr #name {
          position: sticky;
          left: 0;
          background: $brand-light;
          box-sizing: border-box;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      tr {
        position: relative;
      }

      #name {
        width: 27vw;
        max-width: 27vw;
        height: 100%;
        padding: unset;

        > article {
          border-radius: 0.25rem;
          border-left: 0.5rem solid $brand-primary;
          padding: 0.25rem 0.75rem;
        }
  
        &.action--travel-\&-logistics > article {
          border-color: $impact-area-travel-background;
        }

        &.action--energy > article {
          border-color: $impact-area-energy-background;
        }

        &.action--facilities > article {
          border-color: $impact-area-facilities-background;
        }

        &.action--supply-chain > article {
          border-color: $impact-area-supply-chain-background;
        }

        &.action--governance > article {
          border-color: $impact-area-governance-background;
        }

        &.action--buildings > article {
          border-color: $impact-area-buildings-background;
        }

        &.action--people > article {
          border-color: $impact-area-people-background;
        }

        .name-icon {
          height: 0.75rem;
          fill: $color-grey-700;
        }

        button {
          min-width: 42px;
        }

        article {
          cursor: pointer;
          flex: 1;
          min-width: 0;
          display: flex;
          flex-direction: column;
          gap: 0.25rem;

          p, a {
            margin: 0;
            width: 100%;
            min-width: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          #tag-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.25rem;

            .tag {
              display: block;
              background-color: $color-grey-100;
              color: $color-grey-900;
            }
          }
        }
      }

      #status {
        .tag--active {
          background-color: $brand-primary;
        }
        
        .tag--completed {
          background-color: $success-primary;
          color: $success-text;
        }
        
        .tag--to-do {
          color: $brand-dark;
          background-color: transparentize($brand-dark, 0.9);
        }
        
        .tag--overdue {
          background-color: $error-primary;
          color: $error-text;
        }
      }
    }
  }
}