@import '../../../styles/_variables.scss';

#actions-summary-widget {
  &.variant--default {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-height: 150px;
    max-height: 338px;
    height: 100%;
    box-sizing: border-box;
    background-color: transparent;
    box-shadow: unset;
    align-items: center;
    justify-content: center;

    .pie-chart--loading {
      height: 100%;
      min-height: 250px;
      aspect-ratio: 1;
      border-radius: 100%;
      animation: skeleton-loading 1s linear infinite alternate;
    }
  }

  &.variant--card {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    width: 100%;
    min-width: 150px;
    height: calc(150px + 2rem);
    box-sizing: border-box;

    main {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      header {
        display: flex;
        flex-direction: row;
        gap: 0.25rem;
        cursor: pointer;
        align-items: flex-end;
        margin-bottom: unset;

        h3 {
          line-height: 2.25rem;
          height: 2.25rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      #completed-stat {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        #value {
          display: flex;
          flex-direction: row;
          gap: 0.25rem;
          align-items: flex-end;

          p {
            font-size: 1rem;
            margin: unset;
          }
        }

        h4 {
          font-size: 2rem;
          line-height: 2rem;
        }
  
        p {
          font-size: 0.75rem;
          color: $font-color-secondary;
        }
      }
      
      &+ div {
        width: 125px !important;
      }
    }
  }

  svg g path {
    cursor: pointer;
    
    &:hover {
      opacity: 0.9;
    }
  }

  .chart-tooltip {
    opacity: 1;
    background-color: $color-grey-20;
    border-left: 0.5rem solid $brand-dark;
    transform: translateY(100px);

    h4, p {
      margin: 0;
      text-wrap: nowrap;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &#todo {
      border-color: $todo-color;
    }
  
    &#overdue {
      border-color: $overdue-color;
    }
  
    &#active {
      border-color: $active-color;
    }
  
    &#completed {
      border-color: $completed-color;
    }
  }

  &.actions-summary-widget--locked {
    #completed-stat::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
      border-radius: 0.25rem;
      background: rgba($brand-light, 0.01);
      backdrop-filter: blur(4px);
      pointer-events: none;
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: #c2cfd6;
  }
  100% {
    background-color: #f0f3f5;
  }
}
