// Fonts
$font-size: 14px;
$font-family: Verdana, Arial;
$header-font-family: Baskerville, Times-New Roman;
$font-color: #011E26;
$font-weight: 400;
$font-color: #011E26;
$letter-spacing: calc($font-size * 0.01);

// Colors
$brand-primary: #E35924;
$brand-primary-light: #E96F42; // Used for on hover
$brand-dark: #011E26;
$brand-light: #FBFAF8;

// Extra Colors
$extra-color-1: #E7ECAE;
$extra-color-2: #213F37;
$extra-color-3: #1BAF94;
$extra-color-4: #C7D043;

// Success Colors
$success-primary: #38a169;
$success-secondary: #38a169;
$success-background: #38a169;
$success-text: $brand-light;

// Error Colors
$error-primary: #ff4040;
$error-secondary: #ff4040;
$error-background: #ff4040;
$error-text: $brand-light;

// Warning Colors
$warning-primary: #ffaa15;
$warning-secondary: #ffaa15;
$warning-background: #ffaa15;
$warning-text: $brand-dark;

// Impact Area Travel Colors
$impact-area-travel-primary: #C7D043;
$impact-area-travel-background: #C7D043;
$impact-area-travel-text: $brand-dark;

// Impact Area Energy Colors
$impact-area-energy-primary: #1BAF94;
$impact-area-energy-background: #1BAF94;
$impact-area-energy-text: $brand-dark;

// Impact Area Facilities Colors
$impact-area-facilities-primary: #213F37;
$impact-area-facilities-background: #213F37;
$impact-area-facilities-text: $brand-light;

// Impact Area Supply chain Colors
$impact-area-supply-chain-primary: #648491;
$impact-area-supply-chain-background: #648491;
$impact-area-supply-chain-text: $brand-light;

// Impact Area Governance Colors
$impact-area-governance-primary: #0D91E2;
$impact-area-governance-background: #0D91E2;
$impact-area-governance-text: $brand-dark;

// Impact Area Buildings Colors
$impact-area-buildings-primary: #1BAF94;
$impact-area-buildings-background: #1BAF94;
$impact-area-buildings-text: $brand-dark;

// Impact Area People Colors
$impact-area-people-primary: #d87093;
$impact-area-people-background: #d87093;
$impact-area-people-text: $brand-dark;

// Ranking Color - Avoidance Percent
$ranking-color-avoidance-percent: #C7D043;
$ranking-color-carbon-footprint-scope-1: #0076A1;
$ranking-color-carbon-footprint-scope-2: #83D1B9;
$ranking-color-carbon-footprint-scope-3: #008575;
$ranking-color-carbon-savings-potential: #0D91E2;
$ranking-color-co-benefit-potential: #213F37;
$ranking-color-cost-saving-potential: #E35924;
$ranking-color-priority: #CE3636;
$ranking-color-quick-win: #46B05D;
$ranking-color-employee-suggested: #d87093;

// Screen Breakpoints
$mobile: 480px;
$tabletPortrait: 768px;
$tabletLandscape: 1024px;
$smallDesktop: 1200px;
$mediumDesktop: 1440px;
$largeDesktop: 1920px;

// White Colors
$color-white-500: #FDFDFB;

// Grey Colors
$color-grey-20: #F6F6F4;
$color-grey-40: #F1F1F0;
$color-grey-60: #ECEDEB;
$color-grey-80: #E7E8E7;
$color-grey-100: #E2E4E3;
$color-grey-200: #C9CECE;

$color-grey-700: #4C6065;
$color-grey-800: #334A50;
$color-grey-900: #1A343B;

$font-color-secondary: $color-grey-700;

$scope-1-color: #0076A1;
$scope-2-color: #83D1B9;
$scope-3-color: #008575;

// Status Colors
$todo-color: $color-grey-100;
$active-color: $brand-primary;
$completed-color: $success-primary;
$overdue-color: $error-primary;

// body {
//   font-size: 16px;
  
//   @media screen and (max-width: $mobile) {
//     /* Styles for mobile phones (less than 480px) */
//     font-size: 14px;
//   }
  
//   @media screen and (min-width: $tabletPortrait) and (max-width: $tabletLandscape) {
//     /* Styles for tablets in portrait and landscape orientations (768px to 1023px) */
//     font-size: 16px;
//   }
  
//   @media screen and (min-width: $smallDesktop) and (max-width: $mediumDesktop) {
//     /* Styles for small and medium desktop screens (1200px to 1439px) */
//     font-size: 18px;
//   }
  
//   @media screen and (min-width: $largeDesktop) {
//     /* Styles for large desktop screens (1920px and above) */
//     font-size: 20px;
//   }
// }
