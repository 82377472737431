@import '../../../styles/_variables.scss';

ol.report-ol {
  font-size: 1rem;
  line-height: 1.2rem;
  list-style: none;
  counter-reset: li;

  li::marker {
    content: none;
  }

  li::before {
    content: counter(li);
    counter-increment: li 1;
    display: inline-block;
    background-image: linear-gradient(
      to bottom right,
      transparent,
      transparent 50%,
      $brand-primary 50%,
      $brand-primary
    );
    height: 0;
    width: 2rem;
    padding-top: 1rem;
    padding-bottom: 1.25rem;
    margin-left: -2.5rem;
    margin-right: 0.5em;
    text-align: right;
    direction: rtl;
    padding-right: 0.1rem;

    @media screen and (max-width: 1300px) {
      height: 0;
      width: 1.7rem;
      padding-top: 0.85rem;
      padding-bottom: 1.0625rem;
      margin-left: -2.125rem;
      margin-right: 0.425em;
      padding-right: 0.085rem;
      font-size: 0.85rem;
    }
  }

  @media screen and (max-width: 1300px) {
    font-size: 0.85rem;
  }
}
