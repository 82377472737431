@import '../../../../styles/_variables.scss';

main#app > :nth-child(2)#data-list-page {
  padding: unset;
}

#data-list-page {
  flex: 1;
  min-width: 0;

  > main {
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 1rem;
    position: relative;

    >dialog {
      position: absolute;
      top: 120px;
    }
  
    .table-section {
      height: 74dvh;
    }

    table#data-period-table {
      margin-bottom: 10rem;
    }

    tr {
      cursor: pointer;

      td {
        min-width: 6dvw;
      }

      #more {
        overflow: visible;
        #actions {
          position: relative;
          overflow: visible;
          display: flex;
          flex-direction: row;
          gap: 0.25rem;
    
          #list-item-menu {
            position: absolute;
            top: 100%;
            left: -60px;
            z-index: 999;
            width: 150px;
          }
        }
      }

      #score {
        > svg {
          width: 3.5rem;
          height: 3.5rem;
        }
      }
    }

    #name {
      min-width: 20dvw;

      article {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        > p {
          max-width: 250px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        #tag-container {
          display: flex;
          flex-direction: row;
          gap: 0.25rem;

          .policy-tag {
            background-color: $warning-background;
            color: $warning-text;
          }

          .policy-tag--compliant {
            background-color: $success-background;
            color: $success-text;
          }
        }
      }
    }
  }

  #empty-list-helper {
    margin: auto;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    transform: translateY(-50dvh);
  }
}