.flotilla-table .action-row {
     > td {
        overflow: visible;
    }

    #recurring,
    #locations {
        > article {
            display: flex;
            align-items: center;
            gap: 0.25rem;
            width: 110px;

            #recurring-times {
                background-color: #E2E4E3;
                color: #1A343B;
            }
        }
    }

    .actions {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: visible;
        gap: 0.25rem;
    
        .list-item-menu {
            position: absolute;
            top: 100%;
            left: -60px;
            z-index: 999;
            width: 150px;
        }
    }

    .scope-category {
        white-space: nowrap;
    }

    .customers {
        overflow: visible;

        >p {
            margin-left: 10px;
        }

        button {
            color: unset;
            text-decoration: underline;
        }

        .tooltip {
            padding-right: 3.5rem;

            .copy-btn {
            position: absolute;
            right: 1rem;
            }
        }
    }
}