@import '../../../../../styles/_variables.scss';

#table-view {
  table {
    width: 100%;
    height: calc(100dvh - 175px);

    thead {
      z-index: 1;
    }

    #name {
      display: block;
      border-radius: 0.25rem;
      border-left: 0.5rem solid $brand-primary;
      width: 100%;
      max-width: 30vw;
      height: 100%;

      &.action--travel-\&-logistics {
        border-color: $impact-area-travel-background;
      }

      &.action--energy {
        border-color: $impact-area-energy-background;
      }

      &.action--facilities {
        border-color: $impact-area-facilities-background;
      }

      &.action--supply-chain {
        border-color: $impact-area-supply-chain-background;
      }

      &.action--governance {
        border-color: $impact-area-governance-background;
      }

      #quick-win {
        height: 0.75rem;
        fill: $color-grey-700;
        margin-right: 0.25rem;
      }

      button {
        min-width: 42px;
      }

      article {
        flex: 1;
        min-width: 0;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        cursor: pointer;

        p {
          margin: 0;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        #tag-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: 0.25rem;

          .tag {
            display: block;
            background-color: $color-grey-100;
            color: $color-grey-900;
          }
        }
      }
    }

    #actions {
      padding: unset;
      padding-top: 0;
      padding-bottom: 0;

      svg {
        padding-top: 4px;
        height: 50px;

        #actions-progress-progress-path {
          stroke: transparentize($brand-dark, 0.9);
        }
      }
    }

    .customers {
      overflow: visible;

      >p {
        margin-left: 10px;
      }

      button {
        color: unset;
        text-decoration: underline;
      }

      .tooltip {
        padding-right: 3.5rem;

        .copy-btn {
          position: absolute;
          right: 1rem;
        }
      }
    }
  }
}