@import '../../../../../../styles/_variables.scss';

.report-header-container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 1.5rem 3rem;
  box-sizing: border-box;
  background-color: $color-grey-60;

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    #title {
      flex: 1;
      line-height: 2rem;
      font-family: $header-font-family;
    }
    
    #section {
      color: $brand-primary;
      font-family: $header-font-family;
    }
  }

  &.report-header-container--editable {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;

    #edit-header {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
    }

    &:hover {
      background-color: $color-grey-40;

      #edit-header {
        display: block;
      }
    }
  }

  #subtitle {
    font-size: 12px;
    font-weight: 500;
  }

  #page-number {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 30px;
  }

  .logo {
    position: absolute;
    top: 0;
    right: 0;
    width: 6%;
    object-fit: contain;
    margin: 30px;
  }
}
