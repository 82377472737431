@import '../../../../../../styles/_variables.scss';

#action-selector {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  
  .actions-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  #selected-actions-container {
    margin: 0.5rem 0;
  }

  #option-actions-container {
    margin-top: 0.5rem;
    max-height: 20rem;
    overflow-y: auto;
  }

  .action-card--reduced-detail {
    height: unset;
    gap: 0.5rem;

    &.action-card--error {
      border-color: $error-primary;
      border-style: solid;
      border-width: 1px 1px 1px 0.5rem;
    }

    .flotilla-tooltip {
      position: absolute;
      right: 5px;
      bottom: 5px;

      .tooltip {
        padding-right: 2.5rem;
      }
    }

    header {
      height: unset;
    }

    #tags {
      flex-wrap: wrap;
    }
  }
}
