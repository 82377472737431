@import '../../../styles/_variables.scss';

.flotilla-modal-overlay {
  z-index: 1;
}

#conversion-factor-modal {
  background-color: $brand-light;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  h2 {
    margin: 0;
  }

  main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: min-content;
    margin: 0;

    .switch-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      width: min-content;
      padding: 0 2rem;
    }
  }
}
