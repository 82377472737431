@import '../../../../../../styles/_variables.scss';

.carbon-footprint-baseline-year-page {
  padding: calc(30px + 1.25rem) calc(70px + 1.25rem);
  position: relative;

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 90%;
    overflow: visible;
    gap: 2rem;

    .chart-side {
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;

      svg {
        overflow: initial;
      }
    }

    .items-side {
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2rem;

      .key-insights-editor {
        border-left: 2px solid $brand-dark;

        .ql-editor {
          padding: 0 0 0 1rem;
        }
      }

      .stats-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &#scope-stats {
          flex-direction: row;
          gap: 1rem;
        }
      }

      .stat {
        width: 100%;
        padding: 0.5rem 1rem;
        border-left: 0.5rem solid $brand-dark;
        border-radius: 0.25rem;
        background-color: transparentize($brand-dark, 0.95);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.5rem;

        .label {
          width: 100%;
        }

        .values-container {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 0.25rem;

          .value {
            font-size: 3rem;
            line-height: 3rem;
          }
        }

        &#scope-1 {
          border-color: #0076a1;
        }

        &#scope-2 {
          border-color: #83d1b9;
        }

        &#scope-3 {
          border-color: #008575;
        }
      }

      .icon-text {
        padding: 0;
        display: flex;
        align-items: flex-start;
        gap: 1.5rem;

        p {
          width: 100%;
        }

        .icon-container {
          width: 3rem;
          height: 3rem;
          aspect-ratio: 1;
          border-radius: 100%;
          background-color: $brand-primary;
          display: flex;
          align-items: center;
          justify-content: center;

          &.travel {
            background-color: $extra-color-4;
          }
        }

        .icon {
          width: 1.5rem;
          box-sizing: border-box;
        }
      }
    }
  }
}
