@import '../../../../../styles/_variables.scss';

.flotilla-card.company-card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
  cursor: pointer;
  height: 180px;
  width: 250px;

  > header {
    min-height: 45px;
    width: 100%;
    min-width: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.5rem;

    .customer-avatar {
      position: absolute;
      top: 1rem;
      left: 1.5rem;
      transition: all linear 500ms;
      z-index: 1;

      img {
        transition: all linear 500ms;
      }
    }

    .title-subtitle-container {
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      margin-left: 52px;
  
      p {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .title {
        font-weight: 700;
        transition: all linear 500ms;
        z-index: 2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  > main {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .contact-info-container {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    
      a {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        width: 1rem;
        padding: 0 0.25rem;
      }

      .icon-text-container {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
      }

      #consultant-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        #avatar {
          width: 1.5rem;
          min-width: 1.5rem;
          height: 1.5rem;
          

          span {
            font-size: 0.75rem;
          }
        }
      }
    }

    #tags {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      flex-wrap: wrap;

      .flotilla-tag {
        background-color: $color-grey-100;
        color: $color-grey-900;

        &#parent-company {
          flex-direction: row-reverse;
          justify-content: flex-end;
          gap: 0.125rem;
          max-width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          svg {
            min-width: 14px;
          }
        }
      }
    }
  }

  #sign-up-button {
    right: -1rem;
  }

  #more-button {
    padding: unset;
    height: 2rem;
    width: 2rem;
    right: -1rem;
  }

  #more-menu {
    position: absolute;
    z-index: 99999;
    width: 220px;
    top: 2.75rem;
    right: 0.5rem;
  }
}