@import '../../../../../../styles/_variables.scss';

#report-description-section {
  position: relative;
  flex: 1;
  padding-top: 2.5dvh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
  max-width: 500px;
  margin: auto;

  #company-description {
    padding-right: 2.5rem;
  }

  #ask-chat-gpt {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0.5rem;
  }
}