@import '../../../../../../../styles/_variables.scss';

#data-period-activity {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $color-grey-40;

  > header {
    min-width: 400px;
    max-width: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;

    #name-container {
      flex: 1;
      max-width: 100%;
      min-width: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      
      label {
        flex: 1;
        max-width: 100%;
        min-width: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        
        #name {
          max-width: 100%;
          min-width: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .required-asterisk {
          color: $error-primary;
          font-weight: 900;
        }
      }

      .scope-tag {
        margin-top: 0.25rem;
      }
    }
  }

  #tco2e {
    flex: 1;
  }

  .number-input {
    max-width: 200px;
  }

  &:last-child {
    border-bottom: unset;
  }

  > main {
    max-width: 100%;
    min-width: 0;
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    .activity-score {
      min-width: 3rem;
      width: 3rem;
      height: 3rem;
      align-self: center;
      margin-left: auto;
      padding-right: 2rem;
    }

    .estimate {
      width: 60px;
      min-width: 60px;
    }
  }
  
  .measure-inputs {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;

    .flotilla-number-input {
      position: relative;
      min-width: 150px;
      max-width: 150px;

      .alert-message {
        position: absolute;
        width: fit-content;
        left: calc(100% + 2px);
        top: 50%;
        transform: translateY(calc(-50% + 2px));
        border-radius: 0.25rem;
        z-index: 2;
        padding: 0.6rem 1rem;

        > p {
          white-space: nowrap;
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .input-item--append {
    width: 5em;
  }

  #note-modal-button {
    align-self: center;

    #note-badge {
      bottom: -0.25rem;
      right: -0.25rem;
      background-color: $success-background;
      svg {
        padding-bottom: 2px;
      }
    }

    &.note-modal-button--disabled {
      visibility: hidden;
    }
  }
}