@import "../../styles/_variables.scss";

.question {  
  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $color-grey-800;
  }

  &--toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    label .tooltip {
      min-width: 250px;

      > p {
        padding-right: 1rem;
      }
    }

    &-disabled {
      .flotilla-switch {
        cursor: default;
      }
    }
  }

  &--checkbox {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .checkbox-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.5rem;

      .break {
        flex-basis: 100%;
        height: 0;
      }
    }
  }

  &--number-input {
    max-width: 300px;
  }

  &--datalistmulti {
    .list-container {
      max-height: 10rem;
    }
  }
}