@import '../../../../../../styles/_variables.scss';

#checklist-tab-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;

  .checklist-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
