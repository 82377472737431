@import '../../../styles/_variables.scss';

#net-zero-summary-widget {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  min-width: 150px;
  height: calc(150px + 2rem);
  box-sizing: border-box;

  > main {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    height: 100%;

    header {
      height: fit-content;
      grid-row: 1;
      grid-column: 1 / 4;
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
      cursor: pointer;
      align-items: flex-end;
      margin-bottom: unset;

      h3 {
        line-height: 2.25rem;
        height: 2.25rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .stat-container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem;
      align-items: flex-start;
      flex: 0 !important;
      margin-right: 2rem;
      margin-bottom: 0.5rem;

      .stat {
        text-align: center;
  
        h4 {
          font-size: 2rem;
          line-height: 2rem;
        }
  
        p {
          font-size: 0.75rem;
          color: $font-color-secondary;
        }
      }
    }
    
    &+ div {
      width: 125px !important;
    }
  }

  .progress-container {
    position: relative;
    > svg {
      height: 130px;
      width: 130px;
  
      text {
        font-size: 18pt !important;
      }
    }

    p {
      font-size: 0.75rem;
      color: $font-color-secondary;
    }
  }

  .progress--loading {
    height: 120px;
    width: 120px;
    aspect-ratio: 1;
  }

  &.net-zero-summary-widget--locked {
    .stat-container::before,
    .progress-container::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
      border-radius: 0.25rem;
      background: rgba($brand-light, 0.01);
      backdrop-filter: blur(4px);
      pointer-events: none;
    }
  }
}
