@import "../../../styles/_variables.scss";

.company-tasks-view {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  padding-bottom: 5rem;

  header {
    width: 100%;
    padding: 1rem;
    padding-bottom: 0.5rem;
    box-sizing: border-box;

    p {
      color: $font-color-secondary;
    }
  }

  main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0.5rem;
    box-sizing: border-box;

    .company-task-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
      padding: 0.5rem;
      margin: 0.25rem 0;
      box-sizing: border-box;
      cursor: pointer;
      border: 2px solid transparent;
      border-radius: 0.25rem;
  
      .main-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        
        #description {
          font-size: 0.75rem;
          color: $font-color-secondary;
          display: -webkit-box;
          line-clamp: 2;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      #completed {
        background-color: $success-primary;
        height: 2rem;
        min-height: 2rem;
        width: 2rem;
        min-width: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 999999px;
        padding-right: 2px;
        padding-bottom: 1px;
        box-sizing: border-box;
      }

      &--completed {
        .main-info {
          #title {
            text-decoration: line-through;
          }
        }
      }

      &--first-not-completed {
        border-color: $brand-primary;
        background-color: $color-grey-40;
      }
    }
  }
}
