@import '../../../../styles/_variables.scss';

#net-zero-year-editable-input {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: baseline;

  &.stat {
    min-height: 3.5rem;
    align-items: center;
    width: 100%;

    .year-input {
      max-width: 190px;
      gap: unset;
    }
  }
}