@import "../../styles/_variables.scss";

.flotilla-nav-bar {
  z-index: 99999;
  box-sizing: border-box;
}

#user-icon-options-menu {
  position: fixed;
  z-index: 99999999;
  left: 0.75rem;
  bottom: 4rem;
}

main#app {
  position: relative;
  display: flex;
  flex-direction: row;

  #layout-side-nav {
    height: 100dvh;
    z-index: 999999;

    .company-name {
      height: 35px;
      padding: 0 0.5rem;
      display: flex;
      align-items: center;
      border-radius: 0.25rem;
  
      &--child {
        background-color: $brand-primary;
        color: $brand-light;
        min-height: 2.5rem;
      }

      p {
        max-width: 100%;
        overflow: hidden;
        display: block;
        text-wrap: nowrap;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &.side-nav--closed {
      #company-name {
        display: none;
      }
    }
  }

  >:nth-child(2) {
    padding: 2rem;
    padding-bottom: 5rem;
    box-sizing: border-box;
  }
}

.site-alert.flotilla-alert {
  position: fixed;
  top: 1.5rem;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  z-index: 999999999;

  .title {
    max-width: calc(100% - 54px);
  }
}

.flotilla-component-library.flotilla-modal-overlay {
  z-index: 99999;
}