@import '../../../styles/_variables.scss';

#survey-summary-widget {
  &.variant--default {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-height: 150px;
    max-height: 338px;
    height: 100%;
    box-sizing: border-box;
    background-color: transparent;
    box-shadow: unset;
    align-items: center;
    justify-content: center;
  }

  &.variant--card {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    width: 100%;
    min-width: 150px;
    height: calc(150px + 2rem);
    box-sizing: border-box;

    main {
      flex: 1;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      row-gap: 2rem;

      header {
        height: fit-content;
        grid-row: 1;
        grid-column: 1 / 3;
        display: flex;
        flex-direction: row;
        gap: 0.25rem;
        cursor: pointer;
        align-items: flex-end;
        margin-bottom: unset;

        h3 {
          line-height: 2.25rem;
          height: 2.25rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .type {
          background-color: $success-primary;
          color: $success-text;
          border-radius: 50px;
          padding: 0 12px;
          width: fit-content;
          font-size: 0.75rem;
          align-self: center;
        }
      }

      .stat {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        #value {
          display: flex;
          flex-direction: row;
          gap: 0.25rem;
          align-items: flex-end;

          p {
            font-size: 1rem;
            margin: unset;
          }
        }

        h4 {
          font-size: 2rem;
          line-height: 2rem;
        }
  
        p {
          font-size: 0.75rem;
          color: $font-color-secondary;
        }
      }
      
      &+ div {
        width: 125px !important;
      }
    }
  }

  #response-rate {
    position: relative;
    svg {
      height: 130px;
      width: 130px;

      text {
        font-size: 18pt !important;
      }
    }
  }

  .progress--loading {
    height: 120px;
    width: 120px;
    aspect-ratio: 1;
  }

  &.survey-summary-widget--locked {
    .stat::before,
    #response-rate::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
      border-radius: 0.25rem;
      background: rgba($brand-light, 0.01);
      backdrop-filter: blur(4px);
      pointer-events: none;
    }
  }
}
