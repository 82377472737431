@import '../../../styles/_variables.scss';

main#app > :nth-child(2)#action-ranking-page {
  padding: unset;
}

#action-ranking-page {
    flex: 1;
    min-width: 0;
  
    > main {
      margin: 0 2rem;
      margin-bottom: 3rem;
      thead {
        top: 3.7rem;
        z-index: 1;
      }

      .loading-spinner {
        margin: auto;
      }

      .flotilla-search-filter header {
        margin-bottom: 0.5rem;

        #filters {
          #quick-filters {
            flex-wrap: wrap;

            .quick-filter:not(:nth-child(1)) {
              position: relative;
              padding-left: 1.5rem;
            };
          
            .quick-filter:not(:nth-child(1))::after {
              content: "";
              position: absolute;
              left: 0;
              background-color: #E35924;
              height: 2rem;
              width: 1rem;
            }
      
            .quick-filter:nth-child(2)::after {
              background-color: $ranking-color-avoidance-percent;
            }
      
            .quick-filter:nth-child(3)::after {
              background-color: $ranking-color-carbon-footprint-scope-1;
            }
      
            .quick-filter:nth-child(4)::after {
              background-color: $ranking-color-carbon-footprint-scope-2;
            }
      
            .quick-filter:nth-child(5)::after {
              background-color: $ranking-color-carbon-footprint-scope-3;
            }
      
            .quick-filter:nth-child(6)::after {
              background-color: $ranking-color-carbon-savings-potential;
            }
      
            .quick-filter:nth-child(7)::after {
              background-color: $ranking-color-co-benefit-potential;
            }
      
            .quick-filter:nth-child(8)::after {
              background-color: $ranking-color-cost-saving-potential;
            }
      
            .quick-filter:nth-child(9)::after {
              background-color: $ranking-color-employee-suggested;
            }

            .quick-filter:nth-child(10)::after {
              background-color: $ranking-color-priority;
            }

            .quick-filter:nth-child(11)::after {
              background-color: $ranking-color-quick-win;
            }
          }

          > div {
            display: none;
          }
        }

        #search {
          display: none;
        }
      }

      .flotilla-table-header:first-of-type {
        padding-left: 0.5rem;
      }

      td {
        padding: 0.5rem 0.75rem;
        border-radius: 0 !important;

        &.ranking-name {
          height: 1px;
          padding: 0;
        }
      }

      .ranking-row {
        cursor: pointer;

        article {
          display: flex;
          height: 100%;
          padding-left: 0.75rem;
          border-left: 0.5rem solid black;
          align-items: center;
        }

        &--AvoidancePercent article {
          border-left-color: $ranking-color-avoidance-percent;
        }

        &--CarbonFootprintScope1 article {
          border-left-color: $ranking-color-carbon-footprint-scope-1;
        }

        &--CarbonFootprintScope2 article {
          border-left-color: $ranking-color-carbon-footprint-scope-2;
        }

        &--CarbonFootprintScope3 article {
          border-left-color: $ranking-color-carbon-footprint-scope-3;
        }

        &--CarbonSavingsPotential article {
          border-left-color: $ranking-color-carbon-savings-potential;
        }

        &--CoBenefitPotential article {
          border-left-color: $ranking-color-co-benefit-potential;
        }

        &--CostSavingsPotential article {
          border-left-color: $ranking-color-cost-saving-potential;
        }

        &--Priority article {
          border-left-color: $ranking-color-priority;
        }

        &--QuickWin article {
          border-left-color: $ranking-color-quick-win;
        }

        &--EmployeeSuggested article {
          border-left-color: $ranking-color-employee-suggested;
        }
      }

      .ranking-score {
        display: flex;
        flex-direction: row;
        align-items: center;

        .flotilla-icon-button {
          margin-left: auto;
          margin-right: 10px;

          svg {
            stroke: $color-grey-900;
          }
        }
      }
    }
}