@import "../../styles/_variables.scss";

.flotilla-tag.tag.quick-win-tag {
  padding: unset;
  width: 22px !important;
  height: 22px !important;
  min-width: 0 !important;
  background-color: gold !important;
  display: flex !important;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;

  #quick-win {
    width: 0.75rem;
    height: 0.75rem;
  }

  #text {
    display: none;
  }

  &--show-label {
    width: auto;
    padding: 0 0.25rem;
    padding-right: 0.5rem;

    #text {
      display: block;
    }
  }

  &.flotilla-tag--medium {
    width: 26px !important;
    height: 26px !important;
  }
}
