@import "../../styles/_variables.scss";

.flotilla-component-library.flotilla-card.fab-menu {
  padding: unset;
}

.fab-content {
  position: relative;
  height: 100%;
  width: 100%;

  #ribbo-iframe {
    height: calc(100% - 42px - 0.5rem);
    width: 100%;
  }

  #view-selector {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 1rem);
    background-color: $color-grey-40;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
}
