@import '../../../styles/_variables.scss';

#submit-modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 80dvh;
  max-width: 600px;
  overflow-y: scroll;

  > header{
    max-width: 95%;
    p {
      color: $font-color-secondary;
    }
  }

  #warning-notes {
    overflow-y: auto;
  }

  #info-alert {
    width: 100%;
    max-width: unset;

    svg#icon {
      width: 2.5rem !important;
    }
  }
}
