@import "../../styles/_variables.scss";

.flotilla-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0 2rem;
  background-color: $brand-light;
  z-index: 99999;
  box-sizing: border-box;

  p#subtitle {
    color: $font-color-secondary;
  }

  header {
    background-color: $brand-light;
    position: sticky;
    top: 0;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $brand-light;
    z-index: 9;
    padding-top: 1rem;

    h2, h3, p { 
      transition: opacity 250ms linear;
    }

    h2, h3 {
      min-width: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }


    p#child {
      flex: 1;
      color: $font-color;
      opacity: 0;
    }

    #back-text-container {
      max-width: 95%;
      display: flex;
      flex-direction: row;
      align-items: center;

      #back-button {
        align-self: flex-start;
      }

      .back-button-header {
        max-width: 94%;
        display: flex;
        flex-direction: row;
        gap: 0.25rem;

        .back-button-header {
          h3 {
            font-size: 1.35rem;
          }
        }
      }
    }

    #back-button {
      position: relative;
      left: -0.6rem;
      flex-direction: row-reverse;
    
      span {
        color: $font-color;
        font-weight: 600;
      }
    }

    #right-children {
      z-index: 99;
      margin-left: auto;
    }
  }

  .btn-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
  }

  &--scrolled {
    border-bottom: solid 1px $color-grey-40;
    position: sticky;
    top: 0;

    p#subtitle:not(.header-subtitle) {
      display: none;
    }

    header {
      padding: 1rem 0;

      > h2,
      #back-text-container > h3 {
        font-size: 1rem;
      }
    }
  }
}
