@import '../../../styles/_variables.scss';

h4.report-h4 {
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-family: $font-family !important;

  @media screen and (max-width: 1300px) {
    font-size: 1.0625rem;
  }
}
