@import '../../../../styles/_variables.scss';

main#app > :nth-child(2)#policy-checker-page {
  padding: unset;
}

#policy-checker-page {
  > main {
    all: unset;
    margin: 2rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    gap: 2rem;

    .policy-checker-summary {
      flex: 2;
      min-width: 300px;
      max-width: 400px;
      position: sticky;
      top: 100px;
    }
  
    #inputs {
      flex: 3;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      min-width: 400px;

      .switch-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .checkbox-input {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .options {
          display: flex;
          flex-direction: row;
          gap: 1rem;
          flex-wrap: wrap;
        }
      }
    }
  }
}