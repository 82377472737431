@import '../../../../styles/_variables.scss';

.report-editor-unordered-list-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .report-editor-unordered-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0;

    @media screen and (max-width: 1300px) {
      gap: 0.85rem;
    }
  }

  @media screen and (max-width: 1300px) {
    gap: 1.275rem;
  }
}
