main#app > :nth-child(2)#surveys-page {
  padding: unset;
}

#surveys-page {
  flex: 1;
  min-width: 0;

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0 2rem;
    margin-top: 2rem;
  }
}