@import '../../../../../../styles/_variables.scss';

#environmental-year-end-section {
  flex: 1;
  padding-top: 2.5dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  max-width: 500px;
  margin: auto;
}