@import '../../../../styles/_variables.scss';

.impact-area-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  padding-left: 1.5rem;
  border-left: 0.5rem solid $brand-dark;

  h1, h4, p {
    margin: unset;
  }
  
  .small-text {
    color: $font-color-secondary;
    font-size: 0.75rem;
  }

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    #label-container {
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }

    #value-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }


  #progress-container {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
    color: $font-color-secondary;

    progress[value] {
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      height: 0.5rem;
      color: $brand-dark;
      border: none;

      &::-webkit-progress-bar {
        background-color: $color-grey-60;
        border-radius: 1rem;
      }

      &::-webkit-progress-value {
        background-color: $brand-dark;
        border-radius: 1rem;
      }
    }
  }

  #actions-container {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    .action {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding-left: 0.75rem;
      cursor: pointer;

      label {
        color: $font-color-secondary;
        font-size: 0.75rem;
        cursor: pointer;
      }

      &::after {
        content: "";
        border-radius: 1rem;
        background-color: $brand-dark;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0.25rem;
        height: 100%;
      }
      
      &--todo {
        &::after {
          content: "";
          background-color: $todo-color;
        }
      }

      &--overdue {
        &::after {
          content: "";
          background-color: $overdue-color;
        }
      }

      &--active {
        &::after {
          content: "";
          background-color: $active-color;
        }
      }

      &--completed {
        &::after {
          content: "";
          background-color: $completed-color;
        }
      }
    }
  }
}