@import '../../../../../../../../styles/_variables.scss';

#how-far-do-you-drive-to-work {
  display: flex;
  flex-direction: column;
  position: relative;

  .VictoryContainer {
    height: calc(100% - 30px) !important;
  }

  .circle-stat {
    padding: 10px;
    background-color: $brand-light;
    border: 10px solid #0b8577;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 0.8rem;
  }

  #average-commute {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 110px;
    height: 110px;
    text-align: center;
    border-width: 7px;
  }
}
