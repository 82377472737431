@import '../../../styles/_variables.scss';

.flotilla-modal-overlay {
  z-index: 1;
}

#set-parent-action-modal {
  width: 50vw;
  max-width: 700px;
  min-width: 300px;
  height: 100vh;
  max-height: 700px;
  overflow: hidden;
  background-color: $brand-light;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  h2 {
    margin: 0;
  }

  main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0;

    .action-card {
      width: 18rem;
      border: 1px solid transparent;

      &.action-card--selected {
        background-color: $color-grey-60;
        border-color: $brand-primary;
      }
    }
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      width: min-content;
      padding: 0 2rem;
    }
  }
}
