@import '../../../../../../styles/_variables.scss';

#details-tab-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1rem;

  #statistics {
    h4 {
      margin-bottom: 1rem;
    }
  
    .statistic-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
  
      p {
        margin: unset;
      }
  
      .label {
        color: $font-color-secondary;
      }
  
      &:hover {
        background-color: $color-grey-40;
      }
    }
  
    hr:last-child {
      display: none;
    }
  }

  #savings-stats {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    row-gap: 1rem;

    h4 {
      width: 100%;
    }

    .savings-stat {
      position: relative;
      border-left: 0.5rem solid transparent;
      padding: 0.5rem 0.75rem;
      min-width: 5rem;
      align-items: center;

      #co-benefits-tooltip {
        position: absolute;
        top: 0;
        right: 0;
      }

      .flotilla-stat-label {
        font-size: 12px;
        max-width: 75px;
      }

      &--low,
      &--Low {
        border-color: $error-primary;
      }

      &--medium,
      &--Medium {
        border-color: $warning-primary;
      }

      &--high,
      &--High {
        border-color: $success-primary;
      }
    }
  }

  #sdgs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    .sdg {
      margin: 0 0.25rem;
      padding: 0.5rem;
      padding-bottom: 1rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      > header {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;

        .sdg-icon {
          height: 3rem;
          width: 3rem;
          border-radius: 0.25rem;
        }

        > p {
          font-size: 1.1rem;
        }
      }

      > p {
        margin-left: 3.5rem;
        margin-right: 1rem;
      }
    }
  }

  #notes {
    position: relative;

    #save-notes {
      position: absolute;
      top: 1.25rem;
      right: 0;
      margin: 0.75rem 0.5rem;
    }
  }

  #all-tags {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    #tags {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.5rem;
    }
  
    .tag {
      background-color: $color-grey-60;
      color: $color-grey-800;
    }
  }
}
