@import '../../../styles/_variables.scss';

.report-editor {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .edit-functions {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #011e2610;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    border-radius: 0.25rem;
    z-index: 9;
  }

  .border-color--orange {
    border-left-color: #e35924 !important;
  }

  &:hover {
    .edit-functions {
      display: flex;
    }
  }
}
