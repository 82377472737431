@import '../../../../../styles/_variables.scss';

#upload-document-modal {
  > main {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  #upload-document {
    padding: 3rem;
  }

  #upload-document-input {
    display: none;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;

    button {
      width: max-content;
      padding: 0 2rem;
    }
  }
}