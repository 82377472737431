@import '../../../../../../styles/_variables.scss';

#extras-section {
  flex: 1;
  padding-top: 2.5dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  max-width: 500px;
  margin: auto;
  
  #edit-avatar {
    position: relative;
    height: 7rem;
    width: 7rem;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    background-color: $brand-dark;
    padding: 20px;
    cursor: pointer;

    #company-logo {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    #camera-icon, #person-icon {
      width: 3rem;
      height: 3rem;
    }

    #camera-icon {
      display: none;
      position: absolute;
    }

    input#logo-file {
      display: none;
    }

    &:hover {
      border: 2px solid $brand-primary;
      background-color: transparentize($brand-dark, 0.3);

      #company-logo {
        opacity: 0.5;
      }

      #camera-icon {
        display: block;
      }
    }
  }

  #company-url {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}