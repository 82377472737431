@import '../../../../styles/_variables.scss';

main#app > :nth-child(2)#location-list-page {
  padding: unset;
}

#location-list-page {
  flex: 1;
  min-width: 0;

  > main {
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 1rem;
    padding-bottom: 10rem;
    overflow-x: hidden;
    min-height: 75dvh;

    tr {
      cursor: pointer;
    }

    #name {
      display: flex;
      align-items: center;
      border-left: solid 0.5rem;
      border-radius: 0.25rem;
      overflow: hidden;
      box-sizing: border-box;

      .container {
        min-height: 42px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.125rem;

        > p {
          max-width: 250px;
          text-wrap: nowrap;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  
    #actions {
      position: relative;
      overflow: visible;

      .container {
        display: flex;
        flex-direction: row;
        gap: 0.125rem;
        align-items: center;

        #actions {
          margin-left: auto;

          #list-item-menu {
            right: 1rem;
            top: 100%;
            z-index: 999;
            min-width: 100px;
          }
        }
      }
    }
  }

  #empty-list-helper {
    margin: auto;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
