@import '../../../../styles/_variables.scss';

#integration-details {
  margin-top: 1rem;

  .data-integration-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
    .flotilla-label > header {
      margin-bottom: 0.5rem;

      h4 {
        color: $brand-dark;
      }
    }

    .pending-auth-section
    {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      
      .flotilla-spinner {
        margin: unset;
      }

      span {
        color: #011e26bf;
      }
    }

    .data-integration-row {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;

      .sync-text {
        font-size: 0.9rem;
        color: #011e26bf;
      }

      > label {
        margin-right: auto;
      }

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }

      #list-item-menu {
        position: absolute;
        bottom: 100%;
        right: 0px;
        z-index: 9;
        width: 120px;
      }
    }
  }
}