@import "../../styles/_variables.scss";

.flotilla-tag.tag.custom-action-tag {
  padding: unset;
  width: 22px !important;
  height: 22px !important;
  min-width: 0 !important;
  background-color: #5D3FD3 !important;
  display: flex !important;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;

  #custom-action {
    width: 1.1rem;
    height: 1.1rem;
  }
  
  #text {
    display: none;
  }

  &--show-label {
    width: auto;
    padding: 0 0.25rem;
    padding-right: 0.5rem;
    color: $brand-light !important; 

    #text {
      display: block;
    }
  }

  &.flotilla-tag--medium {
    width: 26px !important;
    height: 26px !important;
  }
}
