@import "../../styles/_variables.scss";

.flotilla-tag.impact-area-tag {
  height: min-content;
  display: block;
  background-color: $color-grey-100;
  color: $color-grey-900;
  
  &.impact-area-tag--travel-\&-logistics {
    background-color: $impact-area-travel-background;
    color: $impact-area-travel-text;
  }
  
  &.impact-area-tag--energy {
    background-color: $impact-area-energy-background;
    color: $impact-area-energy-text;
  }
  
  &.impact-area-tag--facilities {
    background-color: $impact-area-facilities-background;
    color: $impact-area-facilities-text;
  }
  
  &.impact-area-tag--supply-chain {
    background-color: $impact-area-supply-chain-background;
    color: $impact-area-supply-chain-text;
  }
  
  &.impact-area-tag--governance {
    background-color: $impact-area-governance-background;
    color: $impact-area-governance-text;
  }
  
  &.impact-area-tag--buildings {
    background-color: $impact-area-buildings-background;
    color: $impact-area-buildings-text;
  }
  
  &.impact-area-tag--people {
    background-color: $impact-area-people-background;
    color: $impact-area-people-text;
  }
}
