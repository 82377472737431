.day-month-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  #day-month-container {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;

    .day-select {
      flex: 1;
    }

    .month-select {
      flex: 3;
    }
  }
}