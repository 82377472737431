@import "../../styles/_variables.scss";

.rag-status {
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &.rag-status--red {
    background-color: $error-background;
  }

  &.rag-status--amber {
    background-color: $warning-background;
  }

  &.rag-status--green {
    background-color: $success-background;
    svg {
      padding-right: 0.2rem;
      padding-bottom: 0.2rem;
    }
  }
}
