@import '../../../../../../styles/_variables.scss';

#department-selector {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .checkbox-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;

    .department-checkbox {
      width: fit-content;
    }
  }

  #skeleton-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    .skeleton-checkbox {
      padding: unset;
      height: 37px;
      width: 120px;
      border: unset;
      box-shadow: none;
      animation: skeleton-loading 1s linear infinite alternate;
    }
  }
}


@keyframes skeleton-loading {
  0% {
    background-color: #c2cfd6;
  }
  100% {
    background-color: #f0f3f5;
  }
}