@import '../../../../../../styles/_variables.scss';

.audit-tab {
  margin-bottom: -6px;
  margin-top: -2px;
}

#auditing {
  display: flex;
  flex-direction: row;
  max-height: calc(100vh - 260px);
  overflow: hidden;
  min-width: 100%;
  padding: 0;
  border: none;

  #download {
    position: absolute;
    right: 0;
  }

  #auditing-tab-content {
    display: flex;
    flex-direction: column;
    min-height: 100px;
    min-width: 100%;

    #table-container {
      min-height: 0;

      .filter-section {
        max-height: 100%;
        display: flex;
        flex-direction: column;

        .flotilla-search-filter {
          padding-right: 3rem;
        }
      }
    }

    thead {
      z-index: 1;
    }
    
    th {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    td {
      width: 1px;
      white-space: nowrap;
      padding: 1rem;
      padding-right: 2rem;
      overflow: visible;
      max-width: 20vw;
      overflow: hidden;
      text-overflow: ellipsis;

      &.name-cell > section {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;

        .flotilla-avatar {
          width: 30px;
          height: 30px;
          min-width: 30px;
          span {
            font-size: 1rem;
          }
        }
      }

      .additional-info {
        display: flex;

        .flotilla-tooltip {
          margin-top: unset;

          > a {
            font-size: 12px;
            margin-left: 4px;
          }

          .tooltip {
            width: 375px;
            white-space: pre-wrap;
            padding-right: 2rem;
          }
        }
      }
    }

    .flotilla-tooltip {
      margin-top: -1rem;
    }
  }
}