@import '../../../../styles/_variables.scss';

main#app > :nth-child(2)#location-detail-page {
  padding: unset;
}

#location-detail-page {
  flex: 1;
  min-width: 0;

  .header-container {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    h3 {
      width: fit-content;
      max-width: 100%;
    }

    .location-color-picker {
      width: min-content;
    }
  }

  > main {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 6rem;
    max-width: 700px;

    h4 {
      color: $font-color-secondary;
    }

    .section-header {
      font-size: 1.2rem;
      margin-bottom: -1rem;
    }

    #address {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 0.125rem;

      h4 {
        margin-bottom: 0.25rem;
      }
    }

    .date {
      input:disabled {
        opacity: unset;
        background-color: rgba(1, 30, 38, 0.02);
      }
    }

    .flotilla-label > header {
      justify-content: unset;
      align-items: center;
    }

    #location-details {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      #label-container {
        justify-content: unset;
        align-items: center;
      }

      label {
        display: flex;
        align-items: center;
      }

      .date {
        max-width: 300px;
      }
  
      #dates {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;

        .input-container {
          align-items: flex-start;
        }

        #label-container {
          height: 2rem;
        }
      }

      #location-type {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
      
        #options {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 1rem;
      
          .checkbox {
            width: fit-content;
            box-sizing: border-box;
          }
        }
      }
    }

    .section-container {
      padding: 0.5rem;
      padding-bottom: 1rem;

      .flotilla-accordion-header {
        display: flex;
        flex-direction: column;
        align-items: unset;
      }

      .accordion-container {
        .flotilla-accordion-content {
          overflow: visible;
        }

        summary {
          background-color: unset;
          border: unset;
        }

        h4 {
          color: unset;
        }

        > main {
          background-color: unset;
          gap: 1.5rem;

          >section,
          >span {
            width: 100%;
          }

          >section>label,
          >span>label {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }

          .section-header {
            font-size: 1.2rem;
            margin-bottom: -1rem;
          }

          >span {
              display: flex;
              align-items: center;

              .flotilla-switch {
                  display: inline-block;
                  margin-left: auto;
              }
          }

          .flotilla-checkbox-button-container {
              .container-buttons {
                  display: flex;
                  flex-direction: row;
                  gap: 1rem;
                  flex-wrap: wrap;
              }
          }
        }

        .checkbox-button {
          height: 33px;
          padding-top: unset;
          padding-bottom: unset;
        }
      }
    }
  }

  .skeleton-input {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    :first-child {
      width: 30%;
      height: 1rem;
    }

    :last-child {
      height: 2rem;
    }
  }

  #leave-modal {
    h4 {
      max-width: 95%;
    }
  
    #actions {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;
    }
  }
}