@import '../../../../../../../styles/_variables.scss';

.glossary {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .content {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .header-row {
    background-color: #348578;
    color: $brand-light;

    .term-cell,
    .description-cell {
      border-top-width: 0;
    }
  }

  .term-cell,
  .description-cell {
    padding: 15px;
    border: 1px solid #44B081;
    border-bottom-width: 0;
  }

  .term-cell {
    flex-basis: 20%;
    border-left-width: 0;
    border-right-width: 0;
  }

  .description-cell {
    flex-basis: 80%;
    border-right-width: 0;
  }
}
