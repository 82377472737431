@import "../../../styles/_variables.scss";

.customer-list-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  // TODO: Test which look better
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding: 1rem 2rem;
  background-color: rgba($brand-dark, 0.02);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

  header {
    display: flex;
    flex-direction: row;
    align-items: center;

    h3 {
      flex: 1;
      margin: 0;
    }

    button {
      width: max-content;
      padding: 0 2rem;
    }
  }

  .customer-list {
    display: flex;
    flex-direction: column;
  }
}