.reportClass {
    display: flex;
    flex: 1;
    margin-top: 1rem;
}

#powerbi-pdf-btn > svg{
    width: 1.5rem;
    height: 1.5rem;
}

#download-options-menu {
    right: 0;
}