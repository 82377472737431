@import '../../../styles/_variables.scss';

main#app > :nth-child(2)#customer-setup-page {
  padding: unset;
}

#customer-setup-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;

  .flotilla-spinner {
    margin: auto;
  }

  > main {
    position: relative;
    padding: 1rem 2rem;
    margin-bottom: 6rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 2rem;

    label, .label-container {
        color: #011e26bf;
    }

    #column-1 {
      flex: 1 1 30%;
      position: sticky;
      top: 6rem;
      max-width: 400px;
      height: fit-content;

      #basic-company-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        padding: 1.5rem;
  
        #edit-avatar {
          position: relative;
          height: 7rem;
          width: 7rem;
          border-radius: 100%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid transparent;
          background-color: $brand-dark;
          padding: 20px;
          cursor: pointer;
  
          #company-logo {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
  
          #camera-icon, #person-icon {
            width: 3rem;
            height: 3rem;
          }
  
          #camera-icon {
            display: none;
            position: absolute;
          }
  
          input#logo-file {
            display: none;
          }
  
          &:hover {
            border: 2px solid $brand-primary;
            background-color: transparentize($brand-dark, 0.3);
  
            #company-logo {
              opacity: 0.5;
            }
  
            #camera-icon {
              display: block;
            }
          }
        }

        #name-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 1rem;
  
          #company-name {
            font-size: 1.1rem;
            font-weight: 500;
            text-align: center;
          }
        }
      }
    }

    #column-2 {
      flex: 2 1 70%;
      max-width: 800px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
  
      .section-container {
        padding: 0.5rem;
        padding-bottom: 1rem;

        .accordion-container {
          summary {
            background-color: unset;
            border: unset;
          }

          > main {
            background-color: unset;
            gap: 1rem;

            >section,
            >span {
              width: 100%;
            }

            #sector-operate {
              width: 100%;
            }

            >section>label,
            >span>label {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
            }

            .section-header {
              font-size: 1.2rem;
              margin-bottom: -1rem;
            }

            >span {
                display: flex;
                align-items: center;

                .flotilla-switch {
                    display: inline-block;
                    margin-left: auto;
                }
            }

            .flotilla-checkbox-button-container {
                .container-buttons {
                    display: flex;
                    flex-direction: row;
                    gap: 1rem;
                    flex-wrap: wrap;
                }
            }

            .vehicles-switch-container label {
              display: flex;
              flex-direction: row;
              align-items: center;

              .tooltip {
                min-width: 250px;
              }

              .tooltip > p {
                padding-right: 1rem;
              }
            }
          }

          .checkbox-button {
            height: 33px;
            padding-top: unset;
            padding-bottom: unset;
          }

          #compliances {
            padding-right: 150px;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    > main {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
  
      #column-1,
      #column-2 {
        max-width: 100%;
        min-width: 100%;
        position: static;
      }
    }
  }
}