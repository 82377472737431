@import '../../../../../../styles/_variables.scss';

.image-comments {
  display: flex;
  flex-direction: row;
  position: relative;

  .comments-container {
    flex: 3;
    padding: calc(30px + 1.25rem) 70px;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    ol {
      list-style: none;
    }

    .comments-content {
      h2 {
        font-size: 1.25rem;
        line-height: 1.25rem;
        margin-top: 1rem;
      }

      p,
      li {
        font-size: 1rem;
        line-height: 1rem;
      }

      li {
        padding-left: 1.25rem;
        padding-top: 1rem;
      }
    }

    @media screen and (max-width: 1300px) {
      gap: 1rem;
    }
  }

  .image-container {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    .image-circle {
      object-fit: cover;
      border-radius: 100%;
      width: 80%;
      aspect-ratio: 1;
    }
  }

  &.image-comments--reversed {
    flex-direction: row-reverse;
    position: relative;

    .image-container {
      .image-circle {
        position: absolute;
        top: -20%;
        right: -10%;
        width: 50%;
      }

      .second-image {
        position: absolute;
        bottom: 10%;
        width: 20%;
      }
    }
  }
}
