@import './styles/styles.scss';

> main {
  max-width: 100dvw;
  min-height: 100dvh;
  overflow-x: hidden;
}

.flotilla-alert-container.alert-container {
  position: fixed;
  top: 60px;
  right: 1rem;
  z-index: 999999999999999;
}

.ribbo-force-closed {
  max-height: 75px !important;
}

input[type='color']::-webkit-color-swatch-wrapper {
  width: 150%;
  height: 150%;
  transform: translate(-25%);
}

.flotilla-modal-overlay .flotilla-modal {
  min-width: 300px;
  width: 50dvw;
  max-width: 500px;
}

.flotilla-component-library.flotilla-fab  {
  position: fixed;
}

.flotilla-tag.quick-filter.quick-filter--unselected {
  background-color: transparent !important;
}

.flotilla-component-library.flotilla-fab {
  z-index: 9999999;
}

.cursor-default {
  cursor: default !important;
}

a.title-link {
  text-decoration: none;
  color: $font-color;

  &:hover {
    color: $font-color;
  }
}

html body h4 {
  font-weight: 300 !important;
}
// For Component Library
.flotilla-tag--small {
  span {
    line-height: 1rem !important;
  }
}
