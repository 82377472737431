@import '../../../styles/_variables.scss';

h2.report-h2 {
  font-size: 2rem;
  line-height: 2rem;

  @media screen and (max-width: 1300px) {
    font-size: 1.7rem;
  }
}
