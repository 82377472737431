@import '../../../styles/_variables.scss';

h1.report-h1 {
  font-size: 3rem;
  line-height: 3rem;

  @media screen and (max-width: 1300px) {
    font-size: 2.55rem;
  }
}
