@import '../../../styles/_variables.scss';

#create-customer-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: min-content;
    margin: 0;

    .switch-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .option-container {
      display: flex;
      gap: 0.25rem;
      flex-direction: column;

      #options {
        display: flex;
        gap: 0.5rem;
        flex-direction: row;
        flex-wrap: wrap;

        .checkbox-loader {
          max-width: 100px;
          height: 35px;
        }
      }
    }
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      width: min-content;
      padding: 0 2rem;
    }
  }
}
