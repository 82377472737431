@import '../../../../../styles/_variables.scss';

.flotilla-card.user-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem 1.5rem;
  cursor: pointer;
  height: 45px;
  width: 300px;

  > header {
    flex: 1;
    width: 100%;
    min-width: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.5rem;

    .customer-avatar {
      position: absolute;
      top: 1rem;
      left: 1.5rem;
      transition: all linear 500ms;
      z-index: 1;

      img {
        transition: all linear 500ms;
      }

      #crown-container {
        background-color: #FCE100;
      }
    }

    .title-subtitle-container {
      position: relative;
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      margin-left: 52px;
  
      p {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .title {
        max-width: 170px;
        font-weight: 700;
        transition: all linear 500ms;
        z-index: 2;
      }

      #status {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 1.5rem;
      }
    }
  }

  #more-button {
    position: absolute;
    top: 0.25rem;
    right: 0.5rem;
  }

  #more-menu {
    position: absolute;
    z-index: 99999;
    width: 200px;
    top: 2.75rem;
    right: 0.5rem;
  }
}