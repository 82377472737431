.side-nav {
  width: 260px;
  min-width: 260px;
  height: 100vh;
  padding: 1rem 0.5rem;
  box-sizing: border-box;
  background-color: #eeefed;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 0.25rem;
  transition: min-width 500ms ease-in-out;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  z-index: 1;

  &::-webkit-scrollbar {
    display: none;
  }

  #logo-toggle {
    min-width: 0px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0 0.25rem;

    .flotilla-logo {
      width: 50px;
      height: auto;
    }
  
    #toggle-button {
      min-width: 2rem;
      svg {
        height: 1rem;
        width: 1rem;
      }
    }
  }

  .avatar-user-name {
    padding: 0.5rem 0.25rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: max-content;

    #avatar {
      height: 2rem;
      width: 2rem;
      min-width: unset;

      span {
        font-size: 0.9rem;
      }
    }
  }

  #tour-item {
    margin-top: auto;
  }

  &.side-nav--closed {
    width: 48px;
    min-width: 48px;

    #toggle-button {
      > svg {
        transition: all 500ms linear;
        transform: rotateY(180deg);
      }
    }

    #logo-toggle {  
      .flotilla-logo {
        opacity: 0;
      }
    }

    .avatar-user-name {
      > span {
        display: none;
      }
    }
  }

  hr {
    margin: 0.25rem 0.5rem;
  }

  .nav-items {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 0.25rem;
  }

  #main-items {
    flex: 1;
    overflow-y: auto;
  }

  #bottom-items {
    background-color: #eeefed;
    width: 100%;
    box-sizing: border-box;
  }
}
