@import '../../../../../../../../styles/_variables.scss';

#activity-tooltip .tooltip {
    z-index: 999999;
    padding-right: 2rem;
    width: 350px;
    font-size: 14px;
    color: $brand-dark;

    label {
      color: $color-grey-700;
      font-size: 0.9rem;
    }

    #tags-container {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      flex-wrap: wrap;
      padding-top: 0.5rem
    }

    .compliance-tag {
      background-color: $color-grey-80;
      color: $font-color;
    }

    hr {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
}