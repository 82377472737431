@import '../../../../styles/_variables.scss';

main#app > :nth-child(2)#net-zero-dashboard-page {
  padding: unset;
}

#net-zero-dashboard-page {
  flex: 1;
  min-width: 0;
  position: relative;

  #edit-targets-btn {
    position: absolute;
    right: 2.5rem;
    top: 2rem;
    z-index: 99999;

    button {
      padding: 0 1rem;
    }
  }

  main {
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 4rem;
    overflow-x: hidden;

    #main-information {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;

      > #emission-impact-areas {
        display: grid;
        align-items: flex-start;
        grid-template-columns: 2fr 1fr;
        column-gap: 2rem;
        row-gap: 1rem;

        #impact-areas {
          grid-row: 1 / 3;
        }
      }

      > #actions-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      #summary-widgets {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        height: 100%;
      }
    }
  }
}