@import '../../../../../../styles/_variables.scss';

.office-commuting-page {
  padding: calc(30px + 1.25rem) calc(70px + 1.25rem);
  padding-left: 10px;
  position: relative;

  #travel-methods-by-office {
    background-color: $color-grey-40;

    svg > rect {
      fill: transparent !important;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
    gap: 0rem;

    h4 {
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 1rem;
      font-size: 15px;
    }
  }
}
