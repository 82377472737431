@import '../../../../styles/_variables.scss';

#card-view {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-bottom: 10rem;

  .user-card--loading {
    height: 120px;
    width: 250px;
    padding: 1rem 1.5rem;
  }
}