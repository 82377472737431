@import '../../../../../../../styles/_variables.scss';

.operational-boundary {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .content {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-bottom: 2rem;

    .scope-column {
      display: flex;
      flex-direction: column;
      max-width: 25%;
      flex: 1;
      background-color: transparentize($scope-3-color, 0.9);

      .scope-cell {
        color: $brand-light;
        background-color: $scope-3-color;
      }

      .scope-cell,
      .activity-cell {
        padding: 9px;
        padding-left: 1rem;
        padding-right: 1rem;
      }
      
      &.scope-1 {
        background-color: transparentize($scope-1-color, 0.9);
  
        .scope-cell {
          background-color: $scope-1-color;
        }
      }
  
      &.scope-2 {
        background-color: transparentize($scope-2-color, 0.9);
  
        .scope-cell {
          background-color: $scope-2-color;
        }
      }

      &[class*="scope-3-"] .scope-cell {
        color: transparent;
      }
    }
  }
}
