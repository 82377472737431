@import '../../../../../../styles/_variables.scss';

#report-output-section {
  flex: 1;
  padding-top: 2.5dvh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
  max-width: 500px;
  margin: auto;

  #options {
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3rem;

    .checkbox {
      min-width: 150px;
      justify-content: center;
      flex-direction: column;
      padding-bottom: 1rem;
    }
  }
}