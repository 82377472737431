@import "../../styles/_variables.scss";

.flotilla-tag.tag.employee-suggested-tag {
  padding: unset;
  min-width: 22px !important;
  min-height: 22px !important;
  background-color: $ranking-color-employee-suggested !important;
  color: $brand-light !important;
  display: flex !important;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;

  #employee-suggested {
    width: 1rem;
    height: 1rem;
  }

  #text {
    display: none;
  }
  
  &--show-label {
    width: auto;
    padding: 0 0.25rem;
    padding-right: 0.5rem;

    #text {
      display: block;
    }
  }

  &.flotilla-tag--medium {
    width: 26px !important;
    height: 26px !important;
  }
}
