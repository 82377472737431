@import '../../../../../../styles/_variables.scss';

.carbon-footprint-trends-explained-page {
  padding: calc(30px + 1.25rem) calc(70px + 1.25rem);

  .content {
    width: 99%;
    height: 85%;
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  .error-message {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
