@import '../../../styles/_variables.scss';

#reduction-chart {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 500px;
  height: 500px;

  > header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title-section {
      display: flex;
      flex-direction: column;
      flex: 1;

      > section {
        display: flex;
        flex-direction: row;
      }
    }

    .option-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }

    .flotilla-tag {
      margin-top: 0.25rem;
      overflow: visible;

      .flotilla-tooltip {
        color: $brand-dark;
        
        .tooltip {
          min-width: 450px;
          white-space: wrap;
        }
      }

      button {
        padding: 0;
        height: 1rem;
        width: 1rem;

        svg {
          stroke: $brand-light;

          circle:last-of-type {
            fill: $brand-light;
          }
        }
      }

      &.success {
        background-color: $success-primary;
      }

      &.failure {
        background-color: $error-primary;
      }
    }
  }

  .chart-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    .tooltip {
      min-width: 300px;
    }
  }

  #chart {
    width: 100%;
    height: 100%;

    > div {
      min-height: 450px;
      max-height: 500px;
    }
  }
  
  #target-marker {
    position: absolute;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    align-items: center;
    justify-content: center;
    background-color: $color-grey-40;

    p {
      margin: unset;
    }
  }

  #tooltip {
    background-color: $color-grey-40;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .item {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: center;
      justify-content: flex-start;
  
      h4 {
        font-size: 1rem;
      }
  
      p {
        font-size: 0.75rem;
        margin: 0;
      }
  
      .indicator {
        width: 1rem;
        height: 1rem;

        &#target, &#fte {
          border-radius: 999999px;
        }

        &#target {
          background-color: $brand-primary;
        }

        &#fte {
          background-color: $color-grey-100;
        }
      }
    }
  }

  &.reduction-chart--loading {
    flex: 1;
    min-width: 300px;
    border-radius: 0.25rem;
    animation: skeleton-loading 1s linear infinite alternate;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: #c2cfd6;
  }
  100% {
    background-color: #f0f3f5;
  }
}
