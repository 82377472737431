@import '../../../styles/_variables.scss';

main#app > :nth-child(2)#accounts-mapping-page {
    padding: unset;
}
  
#accounts-mapping-page {
  width: 100%;

  #subtitle {
    margin-right: 75px;
  }

  .flotilla-spinner {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20%;
  }

  .pending-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;

    > svg {
      margin-bottom: 1rem;
    }
  }

  main {
    padding: 2rem;
    padding-top: 2rem;
    padding-bottom: 10rem;

    #info-message {
      width: 100%;
      max-width: unset;
      display: block;
      position: unset;

      svg {
        min-width: 2rem;
      }
    }

    #groupings-matcher {
      flex: 2 1 70%;
      max-width: 800px;
      padding-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;

      #header-divider {
        border-color: $color-grey-700;
      }

      > header,
      > .grouping {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: flex-start;
        
        > :first-child {
          width: 40%;
          max-width: 40%;
        }
    
        > :last-child {
          width: 55%;

          .list-container {
            transform: translateY(2.5rem);
          }
        }

        .label {
          min-height: 35px;
          label {
            text-wrap: nowrap;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      hr {
        width: 92%;
      }

      > .predicated-label {
        grid-column: 3;
      }

      .error input {
        border-color: $error-primary;
      }

      .warning input {
        border-color: $warning-primary;
      }

      .success input {
        border-color: $success-primary;
      }

      svg.error #-progress-value {
        stroke: $error-primary !important;
      }

      svg.warning #-progress-value {
        stroke: $warning-primary !important;
      }

      svg.success #-progress-value {
        stroke: $success-primary !important;
      }

      > section {
        display: flex;
        flex-direction: row;
        align-items: center;
        
        > * {
          display: flex;
          flex-basis: 50%;
        }
      }
    }
  }


  #content {
    position: relative;
    padding: 1rem 2rem;
    margin-bottom: 6rem;
    display: flex;
    justify-content: flex-start;
    gap: 2rem;

    aside {
      flex: 1 1 40%;
      position: sticky;
      top: 6rem;
      max-width: 400px;
      width: 100%;
      height: fit-content;
  
      #groupings-list {
        width: 100%;
        box-sizing: border-box;

        p#subtitle {
          margin: unset;
          margin-bottom: 1rem;
          color: $font-color-secondary
        }

        #groupings {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 1rem;
        }
      }
    }
  }
}

.flotilla-datalistmulti {
  flex-direction: column;
}