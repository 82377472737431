@import '../../../../../../styles/_variables.scss';

#location-extras-section {
  flex: 1;
  padding-top: 2.5dvh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  width: 100%;
  max-width: 500px;
  margin: auto;

  .flotilla-card.tooltip {
    transform: translate(-95%, 12%);
  }

  .question--loading {
    width: 100%;
    min-height: 5rem;
  }
}