@import '../../../../../../styles/_variables.scss';

#location-type-section {
  flex: 1;
  padding-top: 2.5dvh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
  max-width: 500px;
  margin: auto;

  #options {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    .checkbox {
      width: fit-content;
      box-sizing: border-box;
    }
  }
}