#delete-ranking-modal {
    h2 {
        margin-right: 2.5rem;
    }

    p {
        text-align: center;
        margin-top: 1.5rem;
    }

    .button-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-top: 2rem;
    }
}