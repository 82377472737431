@import '../../../../../../styles/_variables.scss';

#sdg-selector {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .sdg-icon {
    border-radius: 0.25rem;
    height: 3rem;
  }

  .checkbox-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;

    .sdg-checkbox {
      width: fit-content;
      padding: unset;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 0.5rem;

      .labels {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0.25rem 0;
        margin-right: 1rem;

        #sub-label {
          font-size: 0.8rem;
          color: $font-color-secondary;
          text-align: left;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .selected-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .selected-sdg {
    flex: 1;
    padding: unset;
    padding-right: 0.5rem;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: 0.5rem;

    .labels {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0.25rem 0;
      margin-right: 1rem;

      #sub-label {
        font-size: 0.8rem;
        color: $font-color-secondary;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
      }
    }

    .close {
      padding: 0.5rem 0.125rem;
    }
  }

  #skeleton-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    .skeleton-checkbox {
      padding: unset;
      height: 3rem;
      width: 3rem;
      border: unset;
      box-shadow: none;
      animation: skeleton-loading 1s linear infinite alternate;
    }
  }
}


@keyframes skeleton-loading {
  0% {
    background-color: #c2cfd6;
  }
  100% {
    background-color: #f0f3f5;
  }
}