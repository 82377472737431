@import '../../styles/_variables.scss';

.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex-wrap: wrap;

  > p {
    color: $color-grey-700;
    padding-bottom: 0.25rem;
  }

  > article {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  &:not(.checkbox-container--filled) {
    > article {
      flex-wrap: wrap;
    }
  }

  &.checkbox-container--filled {
    > article {
      section {
        width: 100%;
  
        .radio {
          width: 100%;
          box-sizing: border-box;
        }
      }
    }

  }
}