@import '../../../../styles/_variables.scss';

#interim-tooltip {
    background-color: $color-grey-40;
    padding: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    max-width: 200px;
    text-align: center;

    p {
        font-size: 0.75rem;
        margin: 0;
    }
}

.interim-element {
    cursor: default;
}