@import '../../styles/_variables.scss';

#reduction-stats-container {
  &.inline {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .stat-row {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      .stat {
        background-color: unset;
        box-shadow: none;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: unset;
        gap: 0.5rem;

        #value {
          font-size: 2rem;
          line-height: 2rem;
          height: 2rem;
          font-weight: 600;
        }

        .difference-icon {
          border-radius: 9999px;
          padding: 0.25rem;
          height: 1rem;
          width: 1rem;
          align-self: flex-end;
          margin-left: auto;
        }

        #down-arrow {
          background-color: $success-primary;
          fill: $success-text;
        }

        #neutral-arrow {
          background-color: $warning-primary;
          fill: $warning-text;
        }
    
        #up-arrow {
          background-color: $error-primary;
          fill: $error-text;
        }

        .flotilla-stat-label {
          color: $color-grey-800;
        }
      }
    }
  }

  &.loading {
    article {
      width: 100px;
      height: 88px;
      animation: skeleton-loading 1s linear infinite alternate;
    }
  
    &.inline {
      article {
        width: 140px;
        height: 32px;
        box-sizing: border-box;
        box-shadow: none;
        animation: skeleton-loading 1s linear infinite alternate;
      }
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: #c2cfd6;
  }
  100% {
    background-color: #f0f3f5;
  }
}