@import "../../styles/_variables.scss";

.flotilla-tag.tag.location-tag {
  padding: 0 0.25rem;
  padding-right: 0.75rem;
  height: 22px !important;
  width: auto;
  background-color: $color-grey-100;
  display: flex !important;
  flex-direction: row-reverse !important;
  align-items: center;
  justify-content: flex-end;

  &.flotilla-tag--medium {
    height: 26px !important;
  }
}
