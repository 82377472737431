@import '../../../../../../styles/_variables.scss';

#survey-final-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 2rem;

  .stat {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.125rem;

    p {
      color: $font-color-secondary;
    }

    h3 {
      >span {
        color: $font-color-secondary;
        font-size: 1rem;
        margin-left: 0.125rem;
      }
    }
  }
}