@import '../../../../styles/_variables.scss';

main#app > :nth-child(2)#survey-create-page {
  padding: unset;
}

#survey-create-page {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  height: 100dvh;

  #side-image {
    position: sticky;
    top: 0;
    height: 100dvh;
    width: 35dvw;
    object-fit: cover;
    border-radius: 0 0.25rem 0.25rem 0;
  }

  > main {
    all: unset;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 2rem 3rem;
    padding-bottom: 10rem;
    gap: 2rem;

    >header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      #progress-indicator {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .indicator {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.25rem;
          button {
            width: auto;
            aspect-ratio: 1;
          }
        }
      }

      h1 {
        margin-top: 2rem;
      }
    }

    >footer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 2rem;
      padding-bottom: 2rem;

      button {
        max-width: fit-content;
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }
}