@import "../../../../../styles/_variables.scss";

#card-item {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  height: 112px;
  cursor: pointer;

  p {
    margin: unset;
  }

  header {
    margin: unset;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.25rem;

    p {
      flex: 1;
      height: 38px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    #type {
      background-color: $color-grey-100;
      border-radius: 0.25rem;
      padding: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 1rem;
        width: 1rem;
      }
    }
  }

  #tags {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    .department-tag {
      background-color: $color-grey-100;
      color: $brand-dark;
    }
  }

  #stats {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    align-self: flex-end;

    hr {
      height: 100%;
      border-left: 0.125rem solid $color-grey-100;
    }

    .stat {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.25rem;
      
      svg {
        height: 1rem;
        width: 1rem;
      }

      p {
        font-size: 0.75rem;
      }
    }
  }
}