@import '../../../../../styles/_variables.scss';

#suggested-actions-view {
  flex: 1;
  background-color: $brand-light;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;
  padding: 0 !important;

  > header {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 1rem;
    border-bottom: 1px solid #F1F1F0;
    padding-right: 2rem;

    #title {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-width: 150px;
      
      p {
        color: $font-color-secondary;
      }
    }

    #reduction-stats-container {
      margin-left: auto;
      margin-right: auto;
    }

    button {
      width: min-content;
    }
  }

  > main {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    padding-bottom: 5rem;
    padding-top: 0;

    #action-card {
      width: unset;
      min-width: 300px;
    }
    
    .suggested-action {
      border-top: 1px solid transparent;
      border-right: 1px solid transparent;
      border-bottom: 1px solid transparent;
      
      &--selected {
        border-top: 1px solid $brand-primary;
        border-right: 1px solid $brand-primary;
        border-bottom: 1px solid $brand-primary;
      }
    }

    #detail-tabs {
      display: flex;
      flex-direction: column;
      flex: 1;

      .tab-container {
        display: flex;
        padding: 2rem;

        &#support-tab {
          padding: 0;
        }

        #document {
          width: 100%;
          min-height: calc(100vh - 9rem);
        }
      }
    }

    .action-updater {
      position: sticky;
      top: -2rem;
      flex: 3;
      height: min-content;
      max-width: 400px;
    }

    #selected-action {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 2rem;
      
      #selected-action-detail {
        display: flex;
        flex-direction: column;
        flex: 2 1;

        >h3 {
          margin-bottom: 0.5rem;
        }

        .tab-selector {
          margin-bottom: 1rem;
        }
      }

      .action-updater {
        flex: 1 1;
        max-width: 500px;
        height: min-content;

        #start-end-dates > section {
          flex-wrap: wrap;

          .flotilla-text-input {
            width: unset;
            flex: 1;
          }
        }
      }

      @media screen and (max-width: $smallDesktop) {
        flex-direction: column;

        #selected-action-detail {
          order: 2;
        }
    
        #net-zero-plan-action-updater {
          order: 1;
          position: relative;
          top: unset;
          max-width: unset;
        }
      }
    }
  }

  > footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      width: min-content;
      padding: 0 2rem;
    }
  }
}