@import '../../../../../../styles/_variables.scss';

.preface-page {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  padding-left: 100px;

  .preface-page-info {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .preface-page-title {
      font-size: 4rem;
      line-height: 4rem;
    }
  }

  .image-stack {
    height: calc(100% + 2.5rem);
  }
}
