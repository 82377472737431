@import '../../../../../../styles/_variables.scss';

.employee-commuting-page {
  padding: calc(30px + 1.25rem) calc(70px + 1.25rem);
  position: relative;

  .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
    gap: 2rem;

    .content-container {
      width: 100%;
      height: 100%;

      .side-header {
        font-size: 1.4rem;
        font-weight: 700;
        text-align: center;
      }

      .section-header {
        font-size: 1.25rem;
        line-height: 1.25rem;
      }
    }

    .left-side {
      display: flex;
      flex-direction: column;
      gap: 20px;
      position: relative;

      #overall-commuting-emissions {
        position: absolute;
        top: 50px;
        right: 10px;
        width: 160px;
        height: 160px;
        font-size: 1rem;
        line-height: 1.1rem;
        text-align: center;
      }
    }

    .right-side {
      display: flex;
      flex-direction: column;
      gap: 20px;
      position: relative;

      section {
        height: 28%;
      }

      h4 {
        margin-bottom: 0.15rem;
      }
    }
  }
}
