@import '../../../styles/_variables.scss';

li.report-ol-li {
  font-size: 1rem;
  padding-left: 1rem;

  @media screen and (max-width: 1300px) {
    font-size: 0.85rem;
  }
}
