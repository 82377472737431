@import '../../../../../../styles/_variables';

#data-period-tab-content {
  display: flex;
  flex-direction: column;

  > header {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0.5rem 1rem;
    background-color: $color-grey-100;

    > section {
      &#total {
        padding-left: 0.5rem;
        min-width: 400px;
      }

      &.measure-total {
        width: 200px;
      }

      p {
        color: $font-color-secondary;
      }
    }
  }

  .data-period-accordion {
    width: 100%;
    box-sizing: border-box;

    #accordion-header {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      padding: 0.5rem 1rem;

      >#title {
        min-width: 400px;
        max-width: 400px;

        section {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        h4 {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 1.15rem;
        }

        > p {
          color: $font-color-secondary;
        }
      }

      >p {
        min-width: 200px;
        max-width: 200px;
      }
    
      #data-quality {
        padding-right: 2.5rem;
        width: fit-content;
        width: 60px;
        min-width: 60px;
      }

      #estimate {
        margin-left: auto;
        width: 60px;
        min-width: 60px;
      }
    }

    &--travel-\&-logistics {
      border-left: 0.5rem solid $impact-area-travel-background;
    }

    &--supply-chain {
      border-left: 0.5rem solid $impact-area-supply-chain-background;
    }
  }

  &.data-period-tab-content--second-level {
    .data-period-accordion {
      #accordion-header {
        background-color: $color-grey-20;

        >#title {
          padding-left: 2rem;
          box-sizing: border-box;
        }

      }

      .data-period-activity {
        #name-container {
          padding-left: 3.5rem;
          box-sizing: border-box;
        }
      }
    }
  }
}