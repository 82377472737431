main#app > :nth-child(2)#conversion-factor-list-page {
  padding: unset;
}

#conversion-factor-list-page {
  flex: 1;
  min-width: 0;

  .flotilla-header {
    margin-bottom: 0.5rem;
  }

  > main {
    height: unset;
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  
    header {
      margin-bottom: 0.5rem;
    }

    .flotilla-string-filter {
      max-width: 600px;
    }

    .flotilla-boolean-filter {
      max-width: 250px;
    }
  }

  #right-children {
    flex-direction: row;
    display: flex;
    gap: 1rem;
  }
}