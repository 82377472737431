@import '../../../../styles/_variables.scss';

.report-editor-ordered-list-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .report-editor-ordered-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media screen and (max-width: 1300px) {
      gap: 0.25rem;
    }
  }

  @media screen and (max-width: 1300px) {
    gap: 0.5rem;
  }
}
