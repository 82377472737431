@import "../../../../../../../styles/variables";

.leaderboard-row {
    display: flex;
    flex-direction: row;
    padding: 0.6rem;
    align-items: stretch;
    margin-top: 0.6rem;

    .tag-container {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
    }

    .quick-win-tag {
        color: $brand-dark;
        margin-right: 0.5rem;
    }

    .row-content {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &.large {
        .position {
            min-width: 28px;
            height: 28px;
            background-color: transparent;
            border: 4px solid #d4af37;
            color: $brand-dark;
            margin-right: 0;
    
            > b {
                position: relative;
                font-size: 1.7rem !important;
                top: 1px;
            }
        }

        &:nth-of-type(2) .position {
            border: 4px solid #c0c0c0;
            color: $brand-dark;
        }

        &:nth-of-type(3) .position {
            border: 4px solid #cd7f32;
            color: $brand-dark;
        }
    }

    .position {
        display: flex;
        min-width: 32px;
        height: 32px;
        border-radius: 999px;
        background-color: $extra-color-2;
        justify-content: center;
        align-items: center;
        color: $brand-light;
        margin-right: 2px;

        > b {
            font-size: 1.4rem !important;
        }
    }

    .description {
        display: flex;
        flex: 1;
        align-items: center;
        margin-bottom: 0.5rem;

        > .name {
            margin-right: 0.5rem;
            margin-left: 0.5rem;
        }

        b, p {
            font-size: 1em;
        }
    }

    .stats {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > p, > b {
            font-size: 12px;
        }

        .impact-area-tag {
            font-weight: 600;
            color: $brand-light;
        }

        .impact-area-tag--travel-\&-logistics {
            color: $brand-dark;
            background-color: $impact-area-travel-background;
        }
    
        .impact-area-tag--energy {
            background-color: $impact-area-energy-background;
        }
    
        .impact-area-tag--facilities {
            background-color: $impact-area-facilities-background;
        }
    
        .impact-area-tag--supply-chain {
            background-color: $impact-area-supply-chain-background;
        }
    
        .impact-area-tag--governance {
            background-color: $impact-area-governance-background;
        }
    }

    .scores {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 1rem;

        .votes {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 0.2rem;

            p {
                font-size: 14px;
            }

            svg {
                height: 18px;
            }
        }
    }
}