@import '../../../../styles/_variables.scss';

.editor-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: auto;
  background: #011e2650;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  button {
    min-height: 42px;
  }

  #report-editor-popup {
    position: relative;
    width: 100%;
    max-width: 1000px;
    height: 70vh;
    max-height: 800px;
    overflow-y: hidden;
    padding: 2rem;
    background-color: #fbfaf8;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    .close-icon {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }

    main {
      all: unset;
      width: 100%;
      padding-bottom: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      overflow-y: auto;
      overflow-x: hidden;

      .list-text-input-container {
        display: flex;
        flex-direction: column;

        header {
          all: unset;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          h4 {
            margin: 0;
          }
        }
      }
    }

    .switch-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      p {
        flex: 1;
      }
    }

    .switch-input-container {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    #text-editor-text-area {
      height: 300px;
    }
  }

  &.editor-container--open {
    display: flex;
  }
}
