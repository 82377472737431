@import '../../../../../../styles/_variables.scss';

#related-actions-tab-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1rem;

  .actions-container {
    h4 {
      margin-bottom: 0.5rem;
    }

    #actions {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      flex-wrap: wrap;

      .related-action {
        max-width: 250px;
      }
    }
  }
}
