@import '../../../../styles/_variables.scss';

main#app > :nth-child(2)#report-detail-page {
  padding: unset;
  overflow: clip;
}

#report-detail-page {
  flex: 1;

  #download-options-menu {
    top: 100%;
    right: 0;
    white-space: nowrap;
  }

  > main {
    padding: 0 2rem;

    section {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  #report-actions {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }
}