@import '../../../../../../styles/_variables.scss';

.carbon-footprinting-key-terms-explained {
  padding: calc(30px + 1.25rem) calc(70px + 1.25rem);

  .content {
    width: 100%;
    flex: 1;
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;

    .side {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  ul li {
    padding-left: 1rem;
    margin-bottom: 1rem;
    border-color: $brand-dark;
  }

  .chart {
    display: flex;
    flex-direction: row;
    align-items: center;

    .legend {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .item {
        border-left: 0.5rem solid $brand-dark;
        border-radius: 0.25rem;
        background-color: transparentize($brand-dark, 0.95);
        padding: 0.5rem 1rem;

        &#scope-1 {
          border-color: #0076a1;
        }

        &#scope-2 {
          border-color: #83d1b9;
        }

        &#scope-3 {
          border-color: #008575;
        }

        .information {
          font-size: 0.75rem;
        }
      }
    }
  }
}
