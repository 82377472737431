@import '../../../../styles/_variables.scss';

main#app > :nth-child(2)#reports-page {
  padding: unset;
}

#reports-page {
  flex: 1;
  min-width: 0;

  > main {
    padding: 1rem 2rem;
    margin-bottom: 300px;
  }

  .flotilla-component-library.flotilla-modal-overlay {
    z-index: 999999;
  }

  #add-menu {
    right: 0;
    overflow: visible;

    .flotilla-menu-item {
      display: flex;
      align-items: center;

      .tooltip {
        padding-right: 2rem;
      }

      &.disabled {
        cursor: default;
        background-color: $color-grey-40;
        color: $color-grey-200;


        .flotilla-tooltip {
          margin-left: 0.5rem;
          color: $brand-dark;
        }
      }  
    }
  }
}