@import '../../../../../../styles/_variables.scss';

#product-selector {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .checkbox-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;

    .checkbox {
      width: fit-content;
    }

    .checkbox--skeleton {
      height: 37px;
      width: 120px;
    }
  }
}
