@import '../../../../../../styles/_variables.scss';

.contents-page {
  color: $brand-light;
  padding: 0;
  position: relative;

  .cover-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 10%;
    object-fit: contain;
    margin: 30px;
  }

  .contents {
    position: relative;
    bottom: 0;
    right: 0;
    height: 92.5%;
    width: 45%;
    margin-left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.5rem;

    .title {
      color: $brand-primary;
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
  
    .contents-group {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 10px;

      .contents-section-title {
        flex: 2;
        line-height: 1.5rem;
        color: $brand-primary;
        font-size: 0.8rem;
      }

      .contents-section-group {
        flex: 3;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        .contents-section-page {
          width: 100;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          gap: 0.5rem;

          p {
            font-size: 0.5rem;
            line-height: 0.6rem;
          }
        }
      }
    }
  }
}
