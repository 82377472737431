main#app > :nth-child(2)#users-page {
  padding: unset;
}

#users-page {
  flex: 1;
  min-width: 0;

  > main {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow-x: hidden;
    min-height: 75dvh;

    #main-content {
      margin-top: 1rem;
    }
  }
}