@import '../../../../../../styles/_variables.scss';

#regulation-and-framework-section {
  flex: 1;
  padding-top: 2.5dvh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
  max-width: 500px;
  margin: auto;

  .container-buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;

    .checkbox-button {
      min-height: 28px;
    }
  }

  .checkbox {
    width: fit-content;
    box-sizing: border-box;

    &--loading {
      height: 33px;
      width: 200px;
    }
  }
}