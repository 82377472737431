@import "../../styles/_variables.scss";

.flotilla-tag.tag.recommended-tag {
  padding: unset;
  width: 22px !important;
  height: 22px !important;
  background-color: #7AD8FE !important;
  display: flex !important;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;

  #recommended {
    width: 1.1rem;
    height: 1.1rem;
  }
  
  &--show-label {
    width: auto;
    padding: 0 0.25rem;
    padding-right: 0.5rem;
    color: $brand-light !important; 
  }

  &.flotilla-tag--medium {
    width: 26px !important;
    height: 26px !important;
  }
}
