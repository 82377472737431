@import '../../../../../../../../styles/_variables.scss';

#note-modal {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  #subtitle {
    color: $font-color-secondary;
  }

  #upload-document {
    padding: 0 1rem;
    width: 100%;
    display: block;
    text-wrap: nowrap;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  #upload-document-input {
    display: none;
  }

  #uploaded-file {
    display: flex;
    flex-direction: row;
    align-items: center;

    a,
    p {
      width: 100%;
      flex: 1;
      display: block;
      text-wrap: nowrap;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}