@import '../../../styles/_variables.scss';

.side-nav-parent-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0.25rem;

  &.side-nav-parent-item--selected {
    .side-nav-item:not(.parent) {
      border-left: 0.25rem solid transparentize($brand-dark, 0.95);
      margin-left: 1.125rem;
      padding-left: 1.375rem;
      width: calc(100% - 1.125rem);
      border-radius: 0 0.25rem 0.25rem 0;

      &:first-child {
        margin-top: 0.25rem;
      }

      &.side-nav-item--selected {
        color: $brand-primary;
        background-color: unset;
        border-color: $brand-primary;

        &:hover {
          background-color: transparentize($brand-dark, 0.9);
        }
      }
    }
  }
}

.side-nav--closed {
  .side-nav-parent-item {
    .side-nav-item:not(.parent) {
      display: none;
    }
  }
}
