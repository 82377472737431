@import '../../../styles/_variables.scss';

#scope-summary-widget {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  min-width: 150px;
  height: 130px;
  box-sizing: border-box;

  .stat {
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-left: 0.5rem solid transparent;

    h3 {
      align-self: flex-start;
      font-size: 1.25rem;
      line-height: 1.25rem;
    }

    h4 {
      font-size: 2rem;
      line-height: 2rem;
    }

    p {
      margin: unset;
      font-size: 0.75rem;
      color: $font-color-secondary;
    }

    #values {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      gap: 0.5rem;
    }

    &#scope-1 {
      border-color: $scope-1-color;
    }

    &#scope-2 {
      border-color: $scope-2-color;
    }

    &#scope-3 {
      border-color: $scope-3-color;
    }

    &.stat--loading {
      flex: 1;
    }
  }

  &.scope-summary-widget--locked::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    border-radius: 0.25rem;
    background: rgba($brand-light, 0.01);
    backdrop-filter: blur(4px);
    pointer-events: none;
  }
}
