@import '../../../../../../styles/_variables.scss';

#net-zero-plan-create-final-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 5rem;

  .stat-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    >h4 {
      width: 100%;
      justify-self: center;
      align-self: center;
    }

    >.stat-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .stat {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.125rem;

        p {
          color: $font-color-secondary;

        }
        h3 {
          >span {
            color: $font-color-secondary;
            font-size: 1rem;
            margin-left: 0.125rem;
          }
        }
      }
    }
  }
}