@import '../../../../../../styles/_variables.scss';

#data-tab-content {
  display: flex;
  flex-direction: column;

  > header {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0.5rem 1rem;
    background-color: $color-grey-100;

    > section {
      &#total {
        padding-left: 0.5rem;
        min-width: 400px;
        box-sizing: border-box;
      }

      &.measure-total {
        width: 200px;
      }

      p {
        color: $font-color-secondary;
      }
    }
  }

  .location-accordion {
    width: 100%;
    box-sizing: border-box;

    > main h4 {
      font-size: 1.15rem;
      flex-grow: 1;
    }

    #accordion-header {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      padding: 0.5rem 1rem;
      padding-left: 1.5rem;

      >#title {
        min-width: calc(400px - 0.5rem);
        max-width: calc(400px - 0.5rem);

        h4 {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        p {
          color: $font-color-secondary;
        }
      }

      >p {
        min-width: 200px;
        max-width: 200px;
      }

      >p:last-child {
        flex: 1;
      }
    }

    &--color {
      #accordion-header {
        padding-left: 1rem;
      }

      .data-period-accordion {
        >main {
          padding-left: 0.5rem;
        }
      }
    }
  }

  #location-accordion-content {
    padding: unset;

    .location-focus-groups {
      width: 100%;
    }
  }

  #actions {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}