@import '../../../../../../../styles/_variables.scss';

.accreditations {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;

  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 3rem;
    gap: 5rem;
    flex: 1;

    .content-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-basis: 20%;
      gap: 3rem;

      .accreditation-logo {
        width: 60%;
        object-fit: contain;
      }
    }

    .content-text {
      display: flex;
      flex-direction: column;
      flex-basis: 80%;
      justify-content: space-evenly;
      padding-bottom: 2rem;

      .testimonial {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .testimonial-name {
          color: #F2A505;
        }

        .testimonial-role {
          margin-top: 0.4rem;
          font-size: 0.9rem;
        }
      }
    }
  }
}
