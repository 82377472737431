@import '../../styles/_variables.scss';

#activity-selector {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  #options,
  #selected-options {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    .checkbox {
      width: fit-content;
      box-sizing: border-box;
    }

    .tag {
      background-color: $color-grey-60;
      color: $color-grey-800;
      gap: 0.5rem;
      cursor: pointer;

      svg {
        cursor: pointer;
      }

      .chevron-icon {
        transform: rotate(180deg);
      }
    }
  }
}