@import '../../../styles/_variables.scss';

#intensity-summary-widget {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  min-width: 150px;
  height: calc(150px + 2rem);
  box-sizing: border-box;

  main {
    flex: 1;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;

    header {
      grid-row: 1;
      grid-column: 1 / 3;
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
      cursor: pointer;
      align-items: flex-start;
      margin-bottom: unset;

      button {
        margin-top: 0.375rem;
      }

      #title {
        display: flex;
        flex-direction: column;
        gap: 0;
      }

      h3 {
        line-height: 2.25rem;
        height: 2.25rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      p {
        margin: unset;
        font-size: 0.75rem;
        line-height: 1rem;
        height: 1rem;
        color: $font-color-secondary;
      }
    }

    .stat {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      #value {
        display: flex;
        flex-direction: row;
        gap: 0.25rem;
        align-items: flex-end;

        p {
          font-size: 1rem;
          margin: unset;
        }
      }

      h4 {
        font-size: 2rem;
        line-height: 2rem;
      }

      p {
        font-size: 0.75rem;
        color: $font-color-secondary;
      }
    }
    
    &+ div {
      width: 125px !important;
    }
  }

  &.intensity-summary-widget--locked {
    .stat::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
      border-radius: 0.25rem;
      background: rgba($brand-light, 0.01);
      backdrop-filter: blur(4px);
      pointer-events: none;
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: #c2cfd6;
  }
  100% {
    background-color: #f0f3f5;
  }
}
