@import '../../../styles/_variables.scss';

#invite-user-modal {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  header {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    
    #company-name {
      color: $font-color-secondary;
      margin: 0;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: min-content;
    margin: 0;

    .switch-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    #roles {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      width: min-content;
      padding: 0 2rem;
    }
  }
}
