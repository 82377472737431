@import '../../../../styles/_variables.scss';

#customers-page {
  > main {
    padding-bottom: 15rem;

    #table-container {
      overflow-x: auto;
      height: calc(100dvh - 200px);

      table {
        width: 100%;

        thead {
          z-index: 1;

          th:first-of-type {
            position: sticky;
            left: 0;
          }
        }

        tbody {
          background-color: $brand-light;
        }
      }
    }
  }
}