@import '../../../../../../styles/_variables.scss';

.accounting-for-growth-page {
  padding: calc(30px + 1.25rem) calc(70px + 1.25rem);
  position: relative;

  .content {
    position: relative;
    flex: 1;
    height: calc(100% - 100px);

    .edit-box {
      width: 40%;
      position: absolute;
      top: 1rem;
      left: 0;
    }

    .main-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .chart {
        width: 60%;
        height: 75%;

        tspan {
          font-size: 0.5rem !important;
          color: $brand-dark !important;
        }
      }

      .table-container {
        box-sizing: border-box;
        width: 100%;
        padding-left: 15%;
        padding-right: 6%;

        .table {
          width: 100%;
          font-size: 1rem;
          border-collapse: collapse;

          tr {
            border-top: 1px solid #d6d5d5;

            &:first-child {
              border-top: none;
            }

            th,
            td {
              padding: 5px 0;
              font-weight: $font-weight;
              line-height: 1.5rem;
            }
          }

          th {
            width: 40%;
            text-align: left;

            .key {
              display: flex;
              flex-direction: row;
              align-items: center;

              .dot {
                height: 20px;
                width: 20px;
                display: inline-block;
                margin-right: 5px;

                &.average {
                  background-color: $brand-primary;
                  border-radius: 50%;
                }

                &.fte {
                  background-color: #c7d043;
                }

                &.turnover {
                  background-color: #83d1b9;
                }
              }
            }
          }

          td {
            text-align: center;
          }
        }
      }
    }
  }
}
