@import '../../../../../styles/_variables.scss';

.report-list-item {
  min-height: 42px;

  &:not(.report-list-item--read-only) {
    cursor: pointer;
  }

  p {
    margin: 0;
  }

  .type-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    width: 100%;
  }

  .type {
    background-color: transparentize($brand-dark, 0.9);
    border-radius: 50px;
    padding: 0 12px;
    width: fit-content;
    font-size: 0.75rem;

    &.error {
      background-color: transparentize($error-primary, 0.7);
    }
  }

  #title {
    #title-container {
      display: flex;
      flex-direction: column;
      gap: 0.125rem;

      #tags-container {
        display: flex;
        flex-direction: row;
        gap: 0.25rem;
        align-items: center;

        .tag {
          background-color: $color-grey-100;
          color: $brand-dark;
          padding-top: 3px;
        }
      }
    }
  }

  #download {
    justify-content: flex-end;
  }

  #download,
  #hidden {
    width: 0;
  }

  #more {
    overflow: visible;
    #actions {
      position: relative;
      overflow: visible;
      display: flex;
      flex-direction: row;
      gap: 0.25rem;

      #list-item-menu {
        position: absolute;
        top: 100%;
        left: -60px;
        z-index: 999;
        width: 160px;
      }
    }
  }
}

#checks {
  overflow: visible;
  position: relative;

  #status-check-container {
    z-index: 99;
    position: absolute;
    right: 2rem;
    min-width: 310px;
    padding: 1rem;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: #c2cfd6;
  }
  100% {
    background-color: #f0f3f5;
  }
}
