@import '../../../styles/_variables.scss';

.report-page {
  width: 100%;
  box-sizing: border-box;
  max-width: 1485px;
  margin: auto;
  aspect-ratio: 1.4142;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  background-color: $brand-light;

  .logo {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 8%;
    object-fit: contain;
    margin: 30px;
  }

  #footer-report-editor {
    width: 100%;
    position: absolute;
    bottom: 2rem;
    left: 0;

    .report-editor-text-container {
      background-color: unset;
      align-items: center;
    }
  }

  ul {
    padding: 0;
    li {
      list-style: none;
      position: relative;
      border-left: 0.5rem solid $brand-primary;
      border-radius: 0.25rem;
      padding: 0 1rem;

      &::before {
        all: unset;
      }
    }
  }

  #page-number {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 25px;
  }

  &--with-header {
    padding-top: 50px;
  }
}
