@import '../../../../../../styles/_variables.scss';

.impact-area-breakdown-page {
  background-color: $brand-light;
  padding: calc(30px + 1.25rem) calc(70px + 1.25rem);
  position: relative;

  .content {
    padding-top: 5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .chart-side {
      flex: 1;
      display: flex;
      flex-direction: column;

      h3 {
        margin-bottom: 1rem;
      }

      .comment-editor {
        height: min-content;
        max-height: 30%;
      }
    }

    .note {
      text-align: center;
      font-style: italic;
    }

    .item-side {
      height: 100%;
      flex: 2;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .header-container {
        width: 100%;
        padding-left: calc(30% + 1rem);
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        box-sizing: border-box;

        .header-tag-container {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.5rem;

          .tag-container {
            flex: 1;
            display: flex;
            justify-content: center;
          }
  
          .header-tag {
            background-color: transparentize($brand-dark, 0.95);
            color: $brand-dark;
          }
  
          .sub-header-tag-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            justify-content: space-evenly;
          }
        }
      }

      .stat-card {
        background-color: transparentize($brand-dark, 0.95);
        border-radius: 0.25rem;
        width: 100%;
        box-sizing: border-box;
        height: 50px;
        padding-left: 1rem;
        gap: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-left: 0.5rem solid transparent;

        .label {
          width: 30%;
        }

        .values {
          flex: 1;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        .value-container {
          flex: 1;
          height: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;
          border-right: 0.25rem solid $brand-light;

          &:last-child {
            border-right: none;
          }
        }

        .value {
          flex: 1;
          width: 100%;
          text-align: center;
          min-width: 0px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          > .percentage {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
