@import '../../../../../styles/_variables.scss';

.company-question-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    &.open .row-content .expand-button svg {
        transform: rotateZ(270deg);
    }

    .row-content {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 0.5rem;
        border-radius: 0.25rem;
        background-color: $brand-light;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

        .arrow-icon {
            &--right {
                margin-left: auto;
            }
        
            &--left {
                margin-right: 1rem;

                path {
                    fill: $brand-light;
                }
            }
        }
    
        .expand-button {
            margin-right: 0.5rem;
            
            svg {
                transform: rotateZ(180deg);
            }
        }
    }

    .answer-list {
        padding: 1rem;
        background-color: $color-grey-40;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        position: relative;
        top: -0.25rem;
        z-index: -1;

        > p {
            padding: 0.5rem;
        }
    }
}