@import '../../../../styles/_variables.scss';

.company-questions-list {
    display: flex;
    flex-direction: row;
    gap: 4rem;

    > section {
        flex: 1;

        h4 {
            margin-bottom: 0.5rem;
            display: flex;
            align-items: center;
            min-height: 42px;

            > button {
                margin-left: 0.5rem;
            }
        }
        
        .selection-list {
            padding-top: 0.25rem;

            .question--loading {
                margin-top: 0.5rem;
                height: 3.5rem;
            }
        }
    }
}