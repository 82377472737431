main#app > :nth-child(2)#analytics-page {
    padding: unset;
}

#analytics-page {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;

    .main-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 0.5rem;

        .reportClass {
            margin: 0 2rem;
            margin-top: 1rem;
        }
    }
}
