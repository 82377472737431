@import '../../../../../../styles/_variables.scss';

.employee-impact-attitudes-page {
  padding: calc(30px + 1.25rem) calc(70px + 1.25rem);
  position: relative;

  .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: 90%;
    overflow: visible;
    gap: 2rem;
    padding-top: 1rem;

    .chart-side {
      width: 50%;
    }

    .items-side {
      .action-title {
        text-align: center;
      }

      .action-list {
        margin-bottom: 0.5rem;
      }
      
      height: 100%;
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}
