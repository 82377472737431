@import '../../../../../../styles/_variables.scss';

#tags-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  
  .tags-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;

    .tag {
      background-color: $color-grey-60;
      color: $color-grey-800;
      gap: 0.5rem;

      svg {
        cursor: pointer;
        width: 0.6rem;
      }
    }
  }

  #add-tag {
    position: relative;

    #add-new-tag {
      position: absolute;
      right: 0;
      bottom: 0;
      margin: 0.25rem;
    }
  }
}


@keyframes skeleton-loading {
  0% {
    background-color: #c2cfd6;
  }
  100% {
    background-color: #f0f3f5;
  }
}